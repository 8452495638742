import React from 'react';

function MenuIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 7.75C2.41421 7.75 2.75 7.41421 2.75 7C2.75 6.58579 2.41421 6.25 2 6.25C1.58579 6.25 1.25 6.58579 1.25 7C1.25 7.41421 1.58579 7.75 2 7.75Z" stroke="#0E192F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 2.5C2.41421 2.5 2.75 2.16421 2.75 1.75C2.75 1.33579 2.41421 1 2 1C1.58579 1 1.25 1.33579 1.25 1.75C1.25 2.16421 1.58579 2.5 2 2.5Z" stroke="#0E192F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 13C2.41421 13 2.75 12.6642 2.75 12.25C2.75 11.8358 2.41421 11.5 2 11.5C1.58579 11.5 1.25 11.8358 1.25 12.25C1.25 12.6642 1.58579 13 2 13Z" stroke="#0E192F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default MenuIcon;
