import React, { useState } from 'react';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useTranslation } from 'next-i18next';
import WarningIcon from '@app/components/icons/warning';
import { ProjectListResponse } from '@app/components/my-organizations-sidebar/project-list-view';
import { selectProjectInfo, setToggleCleared } from '@app/store/project/slice';
import { useDeleteMemberFromProjectsMutation } from '@app/store/project/api';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';

interface IRemoveMemberModal {
    projects: Array<ProjectListResponse>;
    userId: string;
}

function RemoveMemberFromProjectsModal({ projects, userId }: IRemoveMemberModal) {
    const { closeModal } = useModal();
    const project = useAppSelector(selectProjectInfo);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [deleteMemberFromProjects] = useDeleteMemberFromProjectsMutation();
    const { t } = useTranslation(['common', 'modal', 'project']);
    const [isLoading, setLoading] = useState(false);
    const handleMemberDeletion = async () => {
        setLoading(true);
        await deleteMemberFromProjects({
            organizationId: router.query.organizationId as string,
            userId: userId,
            projectIds: projects.map((project) => project.projectId)
        }).then((response) => {
            if ('data' in response) {
                closeModal();
                dispatch(setToggleCleared(!project.toggleCleared));
                toast.success(`${t('toast.memberRemoved')}`, { toastId: ToastId.REMOVE_ROLE_TOAST });
            }
            if ('error' in response) {
                // @ts-ignore
                closeModal();
            }
            setLoading(false);
        });
    };

    return (
        <div className="flex w-full max-w-[500px] flex-col gap-6 rounded-xl bg-white ">
            <div className="flex flex-col items-center gap-6 px-6 py-4">
                <WarningIcon />
                <div className={'h4'}>{t('removeMember')}</div>
                <div className="flex flex-col items-center gap-2 text-center">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('areYouSureYouWantToRemoveProjectMember', { ns: 'modal', name: projects.length === 1 ? `”${projects[0].projectName}”` : `${projects.length} ${t('projects', { ns: 'project' })}` })
                        }}
                        className="p2 font-normal text-gray-500"
                    ></p>
                </div>
            </div>
            <div className="grid w-full !max-w-[500px] grid-rows-2 gap-4 px-6 py-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[45px]  w-full !border-gray-300 !bg-white  !text-gray-900 hover:!bg-gray-100 hover:!text-gray-900'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button className={'h-[45px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600'} variant={'solid'} isLoading={isLoading} data-testid="remove-member" onClick={handleMemberDeletion}>
                    {t('removeNow', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
}

export default RemoveMemberFromProjectsModal;
