import DataTable from 'react-data-table-component';
import { assignedTableStyles } from '@app/components/datatable/pdf-table/datatable-styles';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useTranslation } from 'next-i18next';
import CustomizeCheckbox from '@app/components/ui/customize-checkbox';
import Loader from '@app/components/ui/loader';
import { ProjectListResponse } from '@app/components/my-organizations-sidebar/project-list-view';
import UpdateRoleDropdown from '@app/components/ui/update-roles-dropdown';
import EmptyProject from '@app/components/ui/empty-project';
import ProjectUserRole from '@app/components/ui/project-user-role';
import { selectAssignedProjectInfo, setAssignedProjectSelectedRows } from '@app/store/project/assign-projects-slice';
import { useRouter } from 'next/router';
import React from 'react';

interface Props {
    data?: Array<ProjectListResponse> | undefined;
    className?: string;
    isLoading?: boolean;
}

function NonAssignedProject({ data, className, isLoading }: Props) {
    const router = useRouter();
    const { t } = useTranslation(['project', 'organization', 'common']);
    const assignedProject = useAppSelector(selectAssignedProjectInfo);
    const dispatch = useAppDispatch();
    const userId = router.query.userId as string;

    const handleSelectedRowsChange = (selected: { allSelected: boolean; selectedCount: number; selectedRows: ProjectListResponse[] }) => {
        dispatch(setAssignedProjectSelectedRows(selected.selectedRows));
    };
    const contextActions = React.useMemo(() => {
        return <UpdateRoleDropdown userId={userId} isAssignProject assignProjects={assignedProject.selectedRows} />;
    }, [assignedProject.selectedRows, userId]);

    const columns: Array<any> = [
        {
            name: <div className={'text-xs font-bold text-black-600'}>{t('projects', { ns: 'project' }).toUpperCase()}</div>,
            selector: (row: ProjectListResponse) => (
                <p data-testid="project-name" className={'mr-1 text-sm text-gray-900'}>
                    {row.projectName}
                </p>
            ),
            width: '280px'
        },

        {
            name: <div className={'text-xs font-bold text-black-600'}>{t('userRole', { ns: 'organization' }).toUpperCase()}</div>,
            selector: (row: ProjectListResponse) => <ProjectUserRole userId={userId} row={row} />,
            width: '220px'
        }
    ];

    return (
        <div className={cn('bg-white', { 'rounded-t-xl': assignedProject.selectedRows.length > 0 }, { 'rounded-xl': !(assignedProject.selectedRows.length > 0) }, className)}>
            {isLoading ? (
                <div className="flex justify-center py-10">
                    <Loader />
                </div>
            ) : data && data.length > 0 ? (
                <DataTable
                    title={<div></div>}
                    className="h-calc-500 max-h-calc-500 bg-white"
                    fixedHeader
                    columns={columns}
                    contextActions={contextActions}
                    data={data}
                    customStyles={assignedTableStyles}
                    progressComponent={<Loader />}
                    //@ts-ignore
                    selectableRowsComponent={CustomizeCheckbox}
                    selectableRowsHighlight
                    selectableRowsHeader
                    pointerOnHover={false}
                    selectableRows
                    clearSelectedRows={assignedProject.toggleCleared}
                    onSelectedRowsChange={handleSelectedRowsChange}
                />
            ) : (
                <EmptyProject className={cn('flex !h-calc-336 !w-[35vw] items-center justify-center')} isAssignProject />
            )}
        </div>
    );
}

export default NonAssignedProject;
