import React, { forwardRef } from 'react';
import { Check } from '@app/components/icons/check';
import cn from 'classnames';

// eslint-disable-next-line react/display-name
const CustomizeCheckbox = forwardRef(({ checked, onChange, onClick, disable }: any, ref) => (
    <label className="inline-flex cursor-pointer items-center">
        <input type="checkbox" className="hidden" disabled={disable} checked={checked} onChange={onChange} onClick={onClick} />
        <span className={cn('flex h-5 w-5 items-center justify-center rounded-md border-2', checked ? 'border-white bg-brand' : 'border-gray-400 bg-white')}>{checked && <Check className="h-4 w-4" color={'#ffff'} strokeWidth={2} />}</span>
    </label>
));

export default CustomizeCheckbox;
