import React, { useEffect, useState } from 'react';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { MemberDto, MemberInvitationDto } from '@app/store/project/types';
import { useAppSelector } from '@app/store/hooks';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { selectAuth } from '@app/store/auth/authSlice';
import { AddMembersToProjectBodyType, useAddMembersToTheProjectMutation } from '@app/store/project/api';
import UseRemoveInvitation from '@app/lib/hooks/use-remove-invitation';
import useUpdateRole from '@app/lib/hooks/use-update-role';
import { RoleType } from '@app/models/enums/role';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import OrganizationHeadings from '@app/components/my-organizations-sidebar/headings';
import AddMembersAutocomplete from '@app/components/my-organizations-sidebar/add-members-autocomplete';
import Index from '@app/components/datatable/member-table';
import { Close } from '@mui/icons-material';
import { Check } from '@app/components/icons/check';

interface IAddMemberModal {
    data: Array<MemberDto>;
    isOrganization?: boolean;
}

function AddMemberModal({ data, isOrganization = false }: IAddMemberModal) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['modal', 'common', 'project']);
    const router = useRouter();
    const auth = useAppSelector(selectAuth);
    const [addMembersToTheProject] = useAddMembersToTheProjectMutation();
    const [dropDownMembers, setDropdownMembers] = useState<Array<MemberDto>>();
    const [selectedMembers, setSelectedMembers] = useState<Array<MemberDto | MemberInvitationDto>>([]);
    const { handleRemoveInvitationApiRequest } = UseRemoveInvitation();
    const [isLoading, setIsLoading] = useState(false);
    const { handleUpdateRoleApiRequest } = useUpdateRole();
    useEffect(() => {
        if (!!data) {
            setDropdownMembers(data);
        }
    }, [data]);

    function handleRemoveMembers(deletedUserId: string) {
        const filteredMembers = selectedMembers.filter((member) => !('userInfo' in member && member.userInfo.userId === deletedUserId));
        setSelectedMembers(filteredMembers);
    }

    const handleRemoveInvitationFromTable = (token: string) => {
        handleRemoveInvitationApiRequest(token).then(() => {
            const filteredMembers = selectedMembers.filter((member) => !('token' in member && member.token === token));
            setSelectedMembers(filteredMembers);
        });
    };

    async function handleRoleChange(member: MemberDto | MemberInvitationDto, role: string) {
        const identifier = 'userInfo' in member ? member.userInfo.userId : member.token;
        setSelectedMembers((prevArray) =>
            prevArray.map((selectedMember) => {
                if ('userInfo' in selectedMember)
                    return selectedMember.userInfo.userId === identifier
                        ? {
                              ...selectedMember,
                              role: role
                          }
                        : selectedMember;
                return selectedMember.token === identifier ? { ...selectedMember, role: role } : selectedMember;
            })
        );
        if ('token' in member) {
            await handleUpdateRoleApiRequest(member, role);
        }
    }

    function getDefaultValue(role: string, member: MemberDto | MemberInvitationDto) {
        if (!!role) {
            return role.toLowerCase();
        } else if ('userInfo' in member && member.userInfo.email === auth.email) {
            return RoleType.ADMIN.toLowerCase();
        } else {
            return RoleType.VIEWER.toLowerCase();
        }
    }

    const handleAddMembers = (chosenMember: MemberDto | MemberInvitationDto) => {
        setSelectedMembers([
            ...selectedMembers,
            {
                ...chosenMember,
                role: getDefaultValue(chosenMember.role, chosenMember)
            }
        ]);
    };

    const getMembersForDropdownList = () => {
        if (!dropDownMembers) return [];
        return dropDownMembers.filter((member) => {
            if (!selectedMembers.some((selectedMember) => 'userInfo' in selectedMember && member.userInfo.email === selectedMember.userInfo.email)) return member;
        });
    };

    const handleDone = async () => {
        setIsLoading(true);
        if (!isOrganization) {
            const selectedMembersDto = selectedMembers
                .filter((member) => 'userInfo' in member && member.userInfo.userId !== '-1')
                .map((member) => {
                    return {
                        userId: 'userInfo' in member ? member.userInfo.userId : '',
                        role: member.role.toLowerCase() ?? 'member'
                    };
                });
            if (selectedMembersDto.length > 0) {
                const requestBody: AddMembersToProjectBodyType = {
                    organizationId: router.query.organizationId as string,
                    projectId: router.query.projectId as string,
                    usersArray: selectedMembersDto
                };
                const response = await addMembersToTheProject(requestBody);
                if ('error' in response) {
                    toast(`${t('toast.failedToAddMembers', { ns: 'common' })}`, {
                        type: 'error',
                        toastId: ToastId.MEMBER_ERROR_TOAST
                    });
                    setIsLoading(false);
                    closeModal();
                }
                if ('data' in response) {
                    toast(`${t('toast.memberAdded', { ns: 'common' })}`, {
                        type: 'success',
                        toastId: ToastId.MEMBER_SUCCESS_TOAST
                    });
                    setIsLoading(false);
                    closeModal();
                }
            } else {
                setIsLoading(false);
                closeModal();
            }
        } else {
            router.back();
        }
    };

    return (
        <div data-testid="add-member-page" className={cn(' flex w-full  max-w-[700px] flex-col items-center justify-center rounded-lg bg-white', router.pathname.includes('add') && 'mt-4')}>
            <div className="m-1 flex flex-col justify-start gap-2 rounded-t-lg bg-gray-200 px-5 pb-3">
                <OrganizationHeadings title={t('getYourTeamOnBoard', { ns: 'project' })} description={t('getYourTeamOnBoardDesc', { ns: 'project' })} />
            </div>

            <div className="z-10 w-full !rounded-t-lg px-6 py-5">
                <AddMembersAutocomplete dropdownListMembers={getMembersForDropdownList()} handleAddMembers={handleAddMembers} />
                <div className="pt-9">
                    <Index
                        displayActionMenu={false}
                        origin={isOrganization ? 'organization' : 'project'}
                        handleRemoveInvitation={handleRemoveInvitationFromTable}
                        isAddMember={true}
                        data={selectedMembers}
                        handleDeletedMembers={handleRemoveMembers}
                        handleRoleChange={handleRoleChange}
                    />
                </div>

                {selectedMembers.length != 0 && (
                    <Button data-testid="done" className={'!mb-2 !mt-[38px] h-[45px] !rounded-md'} startIcon={isLoading ? '' : <Check />} variant={'solid'} fullWidth isLoading={isLoading} disabled={isLoading} onClick={handleDone}>
                        {t('done', { ns: 'project' })}
                    </Button>
                )}
            </div>

            <div className="absolute right-5 top-5 cursor-pointer text-gray-900 hover:text-black" onClick={() => closeModal()}>
                <Close className=" h-6 w-6 text-black-2 dark:text-white" />
            </div>
        </div>
    );
}

export default AddMemberModal;
