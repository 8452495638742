import React, { useState } from 'react';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { ProjectIdWithRoleDto } from '@app/store/project/types';
import { capitalize } from '@app/utils/stringUtils';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ToastId } from '@app/constants/ToastId';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { setToggleCleared } from '@app/store/project/slice';
import { useUpdateProjectRoleOfMemberMutation } from '@app/store/project/api';
import { selectProjectInfo, setSelectedRows } from '@app/store/project/slice';
import { toast } from 'react-toastify';
import { ProjectListResponse } from '@app/components/my-organizations-sidebar/project-list-view';

function ChangeMemberRoleModal({ projects, userId }: { projects: Array<ProjectListResponse>; userId: string }) {
    const { closeModal } = useModal();
    const project = useAppSelector(selectProjectInfo);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [updateProjectRoleOfMember] = useUpdateProjectRoleOfMemberMutation();

    const { t } = useTranslation(['common', 'modal', 'project']);
    const [isLoading, setLoading] = useState(false);
    const handleRoleUpdation = async () => {
        setLoading(true);
        await updateProjectRoleOfMember({
            organizationId: router.query.organizationId as string,
            userId: userId,
            projects: projects.map((project) => ({
                projectId: project.projectId,
                role: project.userRole
            }))
        }).then((response) => {
            if ('data' in response) {
                dispatch(setToggleCleared(!project.toggleCleared));
                dispatch(setSelectedRows([]));
                closeModal();
                toast.success(`${t('toast.roleUpdated')}`, { toastId: ToastId.ROLE_UPDATE_TOAST });
            }
            if ('error' in response) {
                // @ts-ignore
                closeModal();
            }
            setLoading(false);
        });
    };

    return (
        <div data-testid="role-modal" className="flex w-full max-w-[466px] flex-col gap-6 rounded-[8px] bg-white">
            <div className="flex flex-col items-center gap-6 px-6 py-4">
                <p className="h4">{`${t('changeRole', { ns: 'common' })}?`}</p>
                <div className="flex flex-col gap-1 text-center">
                    <p
                        className="p2 text-gray-500"
                        dangerouslySetInnerHTML={{
                            __html: t('changeProjectMemberRoleDescription', {
                                ns: 'modal',
                                name: projects.length === 1 ? `${t('thisProject', { ns: 'project' })}` : `${projects.length} ${t('projects', { ns: 'project' })}`,
                                newRole: capitalize(t(`roleType.${projects[0].userRole.toLowerCase()}`, { ns: 'common' }))
                            })
                        }}
                    ></p>
                </div>
            </div>
            <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 px-6 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[44px]  w-full border-gray-300 !bg-white !text-gray-900 hover:!bg-gray-50'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button className={'h-[44px] w-full !bg-brand hover:!bg-blue-600'} variant={'solid'} data-testid="change-role-button" isLoading={isLoading} onClick={handleRoleUpdation}>
                    {t('changeRole', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
}

export default ChangeMemberRoleModal;
