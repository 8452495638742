import React from 'react';
import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import { MenuItem } from '@mui/material';
import { Check } from '@app/components/icons/check';
import { capitalize } from '@app/utils/stringUtils';
import { useRouter } from 'next/router';
import { isGreaterThanOrSameRole } from '@app/utils/roleUtils';
import { selectActiveOrganization, selectActiveProject } from '@app/store/active/slice';
import { useAppSelector } from '@app/store/hooks';
import { RoleType } from '@app/models/enums/role';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { MemberDto } from '@app/store/project/types';
import { selectAuth } from '@app/store/auth/authSlice';

export interface DropdownDto {
    label: string;
    value: RoleType;
}

interface IMemberFilter {
    isInvitation?: boolean;
    currentRole: string;
    handleUpdateRole: (role: string) => void;
    handleRemoveMember: () => void;
    member?: MemberDto;
    id?: string;
    getBackgroundColor: (role: string) => string;
}

function MemberRoleDropdown({ id, currentRole, isInvitation = false, handleUpdateRole, handleRemoveMember, member, getBackgroundColor }: IMemberFilter) {
    const router = useRouter();
    const isProject = router.query.projectId;
    const auth = useAppSelector(selectAuth);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const activeProject = useAppSelector(selectActiveProject);
    const { t } = useTranslation(['common']);
    const selfMemberAdditionRole: Array<DropdownDto> = [
        {
            value: RoleType.ADMIN,
            label: t('roleType.admin')
        },
        {
            value: RoleType.PROJECT_ADMIN,
            label: t('roleType.project_admin')
        }
    ];
    const projectMemberRole: Array<DropdownDto> = [
        {
            value: RoleType.ADMIN,
            label: t('roleType.admin')
        },
        {
            value: RoleType.EDITOR,
            label: t('roleType.editor')
        },
        {
            value: RoleType.REVIEWER,
            label: t('roleType.reviewer')
        },
        {
            value: RoleType.VIEWER,
            label: t('roleType.viewer')
        }
    ];
    const organizationMemberRole: Array<DropdownDto> = [
        {
            value: RoleType.ADMIN,
            label: t('roleType.admin')
        },
        {
            value: RoleType.PROJECT_ADMIN,
            label: t('roleType.project_admin')
        },
        {
            value: RoleType.MEMBER,
            label: t('roleType.member')
        }
    ];
    const DropdownItems: Array<DropdownDto> = !!member && member.userInfo.email === auth.email ? selfMemberAdditionRole : isProject ? projectMemberRole : organizationMemberRole;

    const isItemDisable = (role: RoleType) => {
        if (isGreaterThanOrSameRole(activeOrganization?.userRole as RoleType, RoleType.ADMIN)) {
            return false;
        }
        const activeRole = router.query.projectId ? (activeProject?.userRole as RoleType) : (activeOrganization?.userRole as RoleType);
        return !isGreaterThanOrSameRole(activeRole, role);
    };

    const isOrganizationAdminOrOwner = isGreaterThanOrSameRole(activeOrganization?.userRole.toLowerCase() as RoleType, RoleType.ADMIN);
    const hasPermissionToRemove = isInvitation || isOrganizationAdminOrOwner || (router.query.projectId ? activeProject?.userRole === RoleType.ADMIN : isGreaterThanOrSameRole(activeOrganization?.userRole as RoleType, RoleType.ADMIN));
    return (
        <MenuDropdown
            className="hover:!bg-transparent"
            showExpandMore={true}
            width={216}
            onClick={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: 250,
                    overflow: 'hidden',
                    borderRadius: 2,
                    filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                    mt: 0.5,
                    px: '2px',
                    border: '1px solid #EEF0F6', // Outer Border
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 2,
                        left: 2,
                        right: 2,
                        bottom: 2,
                        border: '1px solid #EEF0F6', // Inner Border Color
                        borderRadius: 'inherit',
                        pointerEvents: 'none'
                    }
                }
            }}
            id={id ?? 'action-menu'}
            menuTitle={''}
            menuContent={
                <p
                    data-testid="current-role"
                    id={currentRole.toLowerCase()}
                    className={cn('p2 rounded-full border-[1px] px-2 text-sm font-medium', isInvitation ? 'border-[#DDE1EA] bg-[#F8F9FD] text-[#0E192F]' : getBackgroundColor(currentRole.toLowerCase()))}
                >
                    {isInvitation && <span>{t('invitedAs')} </span>}
                    {capitalize(t(`roleType.${currentRole.toLowerCase()}`))}
                </p>
            }
        >
            {DropdownItems.map((dropdownItem) => {
                return (
                    <MenuItem
                        data-testid={`role-${dropdownItem.value}`}
                        disabled={isItemDisable(dropdownItem.value)}
                        onClick={() => {
                            if (dropdownItem.value.toLowerCase() !== currentRole.toLowerCase()) handleUpdateRole(dropdownItem.value);
                        }}
                        className={cn('justify-between px-2 hover:bg-gray-100')}
                        key={dropdownItem.label}
                    >
                        <p className="p2 px-2 py-1 font-medium text-gray-900">
                            {isInvitation && <span>{t('invitedAs')} </span>} {dropdownItem.label}
                        </p>
                        {currentRole?.toLowerCase() === dropdownItem.value && <Check className="h-6 w-6" color="#0764EB" />}
                    </MenuItem>
                );
            })}

            {/* {hasPermissionToRemove && (
                <div>
                    <hr className=" h-[1px] w-full bg-black-300"></hr>
                    <MenuItem onClick={handleRemoveMember} data-testid="remove-participant" className="flex items-start gap-1 px-4 py-2">
                        <DeleteIcon />
                        <p className="p2 text-red-500">{isInvitation ? t('removeInvitation') : t('removeMember')}</p>
                    </MenuItem>
                </div>
            )} */}
        </MenuDropdown>
    );
}

export default MemberRoleDropdown;
