import React from 'react';
import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import { capitalize, MenuItem } from '@mui/material';
import { RoleType } from '@app/models/enums/role';
import { useModal } from '@app/components/modal-views/context';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { DropdownDto } from './member-role-dropdown';
import { useDispatch } from 'react-redux';
import { updateAssignProjectSelectedRowRole } from '@app/store/project/assign-projects-slice';
import { ProjectIdWithRoleDto } from '@app/store/project/types';
import { ProjectListResponse } from '../my-organizations-sidebar/project-list-view';

interface IMemberRoles {
    currentRole?: string;
    isAssignProject?: boolean;
    id?: string;
    userId: string;
    projects?: Array<ProjectListResponse>;
    assignProjects?: Array<ProjectIdWithRoleDto>;
}

function UpdateRoleDropdown({ currentRole, id, userId, projects, isAssignProject, assignProjects }: IMemberRoles) {
    const { openModal } = useModal();
    const { t } = useTranslation(['common', 'organization']);
    const dispatch = useDispatch();
    const handleRoleChange = (newRole: RoleType) => {
        if (isAssignProject) {
            const updatedProjects = assignProjects?.map((project) => ({
                projectId: project.projectId,
                role: newRole
            }));
            dispatch(updateAssignProjectSelectedRowRole(updatedProjects ?? []));
        } else {
            const updatedProjects = projects?.map((project) => ({
                projectId: project.projectId,
                userRole: newRole
            }));
            openModal('CHANGE_MEMBER_ROLE', {
                projects: updatedProjects,
                userId
            });
        }
    };

    const projectMemberRole: Array<DropdownDto> = [
        {
            value: RoleType.ADMIN,
            label: t('roleType.admin')
        },
        {
            value: RoleType.EDITOR,
            label: t('roleType.editor')
        },
        {
            value: RoleType.REVIEWER,
            label: t('roleType.reviewer')
        },
        {
            value: RoleType.VIEWER,
            label: t('roleType.viewer')
        }
    ];

    return (
        <MenuDropdown
            className="hover:!bg-transparent"
            showExpandMore={true}
            width={150}
            onClick={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: 150,
                    overflow: 'hidden',
                    borderRadius: 2,
                    filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                    mt: 0.5,
                    px: '2px',
                    border: '1px solid #EEF0F6', // Outer Border
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 2,
                        left: 2,
                        right: 2,
                        bottom: 2,
                        border: '1px solid #EEF0F6', // Inner Border Color
                        borderRadius: 'inherit',
                        pointerEvents: 'none'
                    }
                }
            }}
            id={id ?? 'action-menu'}
            menuTitle={''}
            menuContent={
                <div data-testid="current-role" id={currentRole?.toLowerCase()} className={cn('rounded-lg border-[1px] border-brand p-2 text-sm font-medium text-brand')}>
                    {capitalize(isAssignProject ? t('assignRole', { ns: 'organization' }) : t('updateRole', { ns: 'organization' }))}
                </div>
            }
        >
            {projectMemberRole.map((dropdownItem) => {
                return (
                    <MenuItem
                        data-testid={`role-${dropdownItem.value}`}
                        onClick={() => {
                            if (dropdownItem.value.toLowerCase() !== currentRole?.toLowerCase()) {
                                handleRoleChange(dropdownItem.value);
                            }
                        }}
                        className={cn('justify-between px-2 hover:bg-gray-100')}
                        key={dropdownItem.label}
                    >
                        <p className="p2 px-2 py-1 font-medium text-gray-900">{dropdownItem.label}</p>
                    </MenuItem>
                );
            })}
        </MenuDropdown>
    );
}

export default UpdateRoleDropdown;
