import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from '@app/store/store';
import { assignProjectStateInterface, ProjectIdWithRoleDto, projectStateInterface } from '@app/store/project/types';

export const initialProjectState: assignProjectStateInterface = {
    query: undefined,
    toggleCleared: false,
    selectedRows: []
};

export const assignProjectSlice = createSlice({
    name: 'assignProjectSlice',
    initialState: initialProjectState,
    reducers: {
        setQuery: (state, action) => {
            return {
                ...state,
                query: action.payload,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },

        resetProjectSliceAndSetQuery: (state, action) => {
            return {
                ...state,
                query: action.payload,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        resetQuery: (state) => {
            return {
                ...state,
                query: undefined
            };
        },
        setToggleCleared: (state, action) => {
            return {
                ...state,
                toggleCleared: action.payload,
                selectedRows: []
            };
        },
        setAssignedProjectSelectedRows: (state, action) => {
            const newSelectedRows = action.payload;
            state.selectedRows = newSelectedRows.map((newRow: ProjectIdWithRoleDto) => {
                const existingProject = state.selectedRows.find((p) => p.projectId === newRow.projectId);
                return existingProject ? { ...existingProject } : newRow;
            });
        },

        resetProjectSlice: (state) => {
            return {
                ...state,
                query: undefined,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        updateAssignProjectSelectedRowRole: (state, action) => {
            const updatedProjects = action.payload;
            state.selectedRows = state.selectedRows.map((project) => {
                const updatedProject = updatedProjects.find((p: ProjectIdWithRoleDto) => p.projectId === project.projectId);
                return updatedProject ? { ...project, role: updatedProject.role } : project;
            });
        }
    }
});

const assignProjectReducer = persistReducer(
    {
        key: 'rtk:assignProjectSlice',
        storage
    },
    assignProjectSlice.reducer
);

const reducerObj = { reducerPath: assignProjectSlice.name, reducer: assignProjectReducer };

export const selectAssignedProjectInfo = (state: RootState) => state.assignProjectSlice;

export const { setAssignedProjectSelectedRows, setToggleCleared, resetProjectSliceAndSetQuery, resetQuery, resetProjectSlice, setQuery, updateAssignProjectSelectedRowRole } = assignProjectSlice.actions;

export default reducerObj;
