import React, { useState } from 'react';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import WarningIcon from '@app/components/icons/warning';
import { useTranslation } from 'next-i18next';

interface IClearWordList {
    handleClearWords: () => void;
}

export default function ClearWordList({ handleClearWords }: IClearWordList) {
    const { closeModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(['common', 'modal', 'project']);
    const handleClear = async () => {
        setIsLoading(true);
        try {
            await handleClearWords();
            closeModal();
        } catch (err) {
            console.error('Error clearing words:', err);
            setIsLoading(false);
        }
    };

    return (
        <div data-testid="delete-modal" className="flex w-full max-w-[500px] flex-col gap-5 rounded-xl border-[1px] border-gray-300 bg-white ">
            <div className="mx-1 flex flex-col items-center gap-5 border-b-[1px] border-gray-200 px-6 py-4">
                <WarningIcon />
                <div className={'h4 text-center font-medium text-gray-900'}>{t('areYouSureYouWantToClearWords', { ns: 'modal' })}</div>
            </div>
            <div className="grid w-full !max-w-[500px] grid-rows-2 gap-2 px-4 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[45px]  w-full !border-gray-300 !bg-white  !text-gray-900 hover:!bg-gray-50 hover:!text-gray-900'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button isLoading={isLoading} className={'h-[45px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600'} variant={'solid'} onClick={handleClear}>
                    {t('clear', { ns: 'project' })}
                </Button>
            </div>
        </div>
    );
}
