import React from 'react';
import { TextField } from '@mui/material';

const MultilineArrayInput = ({ label, isDisabled, value, setValue, onBlur, inputPlaceholder }: { label?: string; isDisabled: boolean; value: string; setValue: any; onBlur?: () => {}; inputPlaceholder?: string }) => {
    const handleChange = (e: any) => {
        setValue(e.target.value.split('\n'));
    };
    return (
        <div className="mb-5 w-full ">
            <h2 className={'mb-2 font-semibold'}>{label}</h2>
            <TextField
                className={'!rounded-xl !bg-[#F8F9FD]'}
                id={`${label}-input`}
                disabled={isDisabled}
                multiline
                rows={4}
                value={value}
                onChange={handleChange}
                placeholder={inputPlaceholder}
                fullWidth
                variant="outlined"
                onBlur={onBlur}
                inputProps={{ style: { resize: 'vertical' }, className: 'custom-scrollbar' }}
            />
        </div>
    );
};
export default MultilineArrayInput;
