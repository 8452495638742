import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { useAppSelector } from '@app/store/hooks';
import { selectActiveOrganization, selectActiveProject } from '@app/store/active/slice';
import { useTranslation } from 'next-i18next';
import { router } from 'next/client';
import { useRouter } from 'next/router';
import WarningIcon from '@app/components/icons/warning';

interface IRemoveInvitation {
    email: string;
    handleRemoveInvitation: () => void;
}

function RemoveInvitation({ handleRemoveInvitation, email }: IRemoveInvitation) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['modal', 'common']);
    const activeProject = useAppSelector(selectActiveProject);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    return (
        <div data-testid="remove-invitation-modal" className="flex w-full max-w-[500px] flex-col gap-5 rounded-xl border-[1px] border-gray-300 bg-white ">
            <div className="m-1 flex flex-col items-center gap-5 border-b-[1px] border-gray-200 px-6 py-4">
                <WarningIcon />
                <div className={'h4'}>{t('removeInvitation')}</div>
                <div className="flex flex-col items-center gap-2 text-center">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t('areYouSureYouWantToRemoveInvitation', {
                                email,
                                name: router.query.projectId ? activeProject?.projectName : activeOrganization?.name
                            })
                        }}
                        className="p2 font-normal text-gray-500"
                    ></p>
                </div>
            </div>
            <div className="grid w-full !max-w-[500px] grid-rows-2 gap-2 px-4 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[45px]  w-full !border-gray-300 !bg-white  !text-gray-900 hover:!bg-gray-50 hover:!text-gray-900'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button
                    isLoading={isLoading}
                    className={'h-[45px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600'}
                    variant={'solid'}
                    data-testid="remove-invitation"
                    onClick={() => {
                        setIsLoading(true);
                        handleRemoveInvitation();
                    }}
                >
                    {t('removeNow', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
}

export default RemoveInvitation;
