import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { toast } from 'react-toastify';
import { useRestorePdfMutation } from '@app/store/pdf/api';
import { pdfResponseDto } from '@app/store/pdf/types';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ToastId } from '@app/constants/ToastId';
import { selectPdfInfo, setToggleCleared } from '@app/store/pdf/slice';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import RecoveryIcon from '@app/components/icons/recovery';

export default function RestoreDocumentModal({ documents }: { documents: Array<pdfResponseDto> }) {
    const { closeModal } = useModal();
    const [restorePdf] = useRestorePdfMutation();
    const router = useRouter();
    const pdf = useAppSelector(selectPdfInfo);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['common', 'modal', 'document']);
    const [isLoading, setLoading] = useState(false);
    const handleRestore = async () => {
        setLoading(true);
        await restorePdf({
            organizationId: router.query.organizationId as string,
            projectId: router.query.projectId as string,
            documentIds: documents.map((document) => document.documentId)
        }).then((response) => {
            if ('data' in response) {
                closeModal();
                dispatch(setToggleCleared(!pdf.toggleCleared));
                toast(`${t('documentRestored', { ns: 'document' })}`, {
                    type: 'success',
                    toastId: ToastId.DOCUMENT_SUCCESS_TOAST
                });
            } else {
                toast(`${t('toast.failedToRestorePdf')}`, { type: 'error', toastId: ToastId.DOCUMENT_ERROR_TOAST });
            }
            setLoading(false);
        });
    };
    return (
        <div data-testid="restore-modal" className="flex w-full max-w-[500px] flex-col gap-5 rounded-[8px] border-[1px] border-gray-300 bg-white ">
            <div className="mx-2 flex flex-col items-center gap-5 border-b-[1px] border-gray-200 px-6 py-4">
                <RecoveryIcon style={{ width: '30px', height: '30px' }} />
                <div className={'h4 text-center font-medium text-gray-500'}>
                    {t('areYouSureYouWantToRestore', { ns: 'modal' })} <Typography className="label break-all"> {documents.length === 1 ? `”${documents[0].documentName}”` : `${documents.length} ${t('documents', { ns: 'document' })}`}?</Typography>
                </div>
            </div>
            <div className="grid w-full !max-w-[500px] grid-rows-2 gap-2 px-4 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={' h-[45px] w-full !border-gray-300 !bg-white  !text-gray-900 hover:!bg-gray-50 hover:!text-gray-900'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button data-testid="restore-document-now" className="h-[45px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600" isLoading={isLoading} variant="solid" color="danger" onClick={handleRestore}>
                    {t('restoreNow')}
                </Button>
            </div>
        </div>
    );
}
