import React from 'react';
import { createTheme, TextField, TextFieldProps, ThemeProvider } from '@mui/material';
import { fontSize } from '@mui/system';

function PdfEditorTextField(props: TextFieldProps) {
    const theme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '::placeholder': {
                            fontWeight: '500',
                            fontSize: '14px'
                        }
                    }
                }
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <TextField
                InputProps={{
                    sx: {
                        height: 48,
                        borderWidth: '3px !important'
                    }
                }}
                {...props}
            />
        </ThemeProvider>
    );
}

export default PdfEditorTextField;
