import { useState } from 'react';
import NewOrganizationWordListTemplate from '@app/components/organization-word-list-template/create';
import AddDetailsToOrganizationWordListTemplate from '@app/components/organization-word-list-template/add-details';

type WordListState = {
    step: number;
    wordListTemplateId?: string;
};

export default function AddWordListModal() {
    const [wordListState, setWordListState] = useState<WordListState>({ step: 1 });

    const handleStepSuccess = (wordListState: WordListState) => {
        setWordListState(wordListState);
    };

    return (
        <div className={'flex h-full w-full flex-col rounded-lg bg-white'}>
            <div className={'m-1 rounded-lg bg-gray-100 p-1'}>
                <div className={'flex gap-2 rounded-lg border-[1px] border-[#FBFCFE] bg-white'}>
                    <div className={'flex-1'}>
                        {wordListState.step === 1 ? <NewOrganizationWordListTemplate handleStepSuccess={handleStepSuccess} /> : <AddDetailsToOrganizationWordListTemplate templateId={wordListState.wordListTemplateId as string} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
