import React from 'react';

function RecoveryIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 5V13.25C3 13.6478 3.15804 14.0294 3.43934 14.3107C3.72064 14.592 4.10218 14.75 4.5 14.75H13.5C13.8978 14.75 14.2794 14.592 14.5607 14.3107C14.842 14.0294 15 13.6478 15 13.25V5M7.5 8H10.5M2.25 1.25H15.75C16.1642 1.25 16.5 1.58579 16.5 2V4.25C16.5 4.66421 16.1642 5 15.75 5H2.25C1.83579 5 1.5 4.66421 1.5 4.25V2C1.5 1.58579 1.83579 1.25 2.25 1.25Z"
                stroke="#0E192F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default RecoveryIcon;
