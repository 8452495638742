import React from 'react';
import { useRouter } from 'next/router';
import AddDetailsToOrganizationWordListTemplate from '@app/components/organization-word-list-template/add-details';

export default function EditWordListModal({ wordListTemplateId }: { wordListTemplateId: string }) {
    return (
        <div className="rounded-xl bg-white">
            <AddDetailsToOrganizationWordListTemplate templateId={wordListTemplateId} />
        </div>
    );
}
