import ProfileImage from '@app/components/auth/profile-image';
import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@app/components/ui/button';
import { NewProjectType } from '@app/pages/[organizationId]/project/create';
import { CreateNewProjectDto } from '@app/models/dtos/projectDto';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import { useAddNewProjectToOrganizationMutation } from '@app/store/project/api';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useModal } from '@app/components/modal-views/context';
import { ProjectListHighlights } from '@app/constants/Constants';

export default function NewProjectDetails({ handleStepSuccess }: any) {
    const initialProjectObj = { name: 'Default', description: '', policyType: '' };

    const [newProjectObj, setNewProjectObj] = useState<NewProjectType>(initialProjectObj);

    const projectFieldRef = useRef<HTMLInputElement | null>(null);

    const router = useRouter();

    const { t } = useTranslation(['common', 'project']);
    const { closeModal } = useModal();

    useEffect(() => {
        if (projectFieldRef.current) {
            projectFieldRef.current.focus();
        }
    }, []);

    const [createNewProject, { isLoading, isSuccess, isError }] = useAddNewProjectToOrganizationMutation();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewProjectObj({ ...newProjectObj, [event.target.id]: event.target.value });
    };

    async function handleApiCall(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const requestBody: CreateNewProjectDto = {
            ...newProjectObj,
            organizationId: router.query.organizationId as string
        };
        const response = await createNewProject(requestBody);
        if ('error' in response) {
            // @ts-ignore
            if (response.error.status === 409) toast.error(`${t('toast.projectNameAlreadyExist')}`, { toastId: ToastId.PROJECT_ERROR_TOAST });
            else toast.error(`${t('toast.errorCreatingNewProject')}`, { toastId: ToastId.PROJECT_ERROR_TOAST });
        }
        if ('data' in response) {
            toast.success(`${t('toast.projectCreated')}`, { toastId: ToastId.PROJECT_SUCCESS_TOAST });
            handleStepSuccess({ step: 2, projectId: response.data.projectId });
        }
    }

    const handleCancel = (e: any) => {
        e.preventDefault();
        closeModal();
    };

    function getColorForProject(name: string) {
        const hash = name.charCodeAt(0);
        return ProjectListHighlights[hash % ProjectListHighlights.length];
    }

    function getProfileAvatarColor(name: string) {
        const finalName = !!name ? name : 'Default';
        const selectedColor = getColorForProject(finalName);
        return {
            color: selectedColor['focus'],
            borderRadius: '0.375rem',
            backgroundColor: selectedColor['background'] + ' !important',
            fontWeight: 700,
            paddingTop: '0.1875rem',
            paddingBottom: '0.1875rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem'
        };
    }

    return (
        <div className="flex flex-col gap-3">
            {/*<ProfileImage name={newProjectObj.name} className="mb-5 flex !h-16 !w-16 items-center justify-center !rounded-[8px] text-lg" />*/}
            <ProfileImage size={64} style={getProfileAvatarColor(newProjectObj.name)} image={null} name={!!newProjectObj.name ? newProjectObj.name : 'Default'} />
            <form onSubmit={handleApiCall}>
                <p className="pb-2 text-sm font-normal">{t('createProject.organizationDetailsTextInputDesc', { ns: 'project' })}</p>
                <PdfEditorTextField
                    data-testid="enter-project-name"
                    id={'name'}
                    inputRef={projectFieldRef}
                    placeholder={t('enterProjectName', { ns: 'project' })}
                    value={newProjectObj.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                    className={'!w-full !rounded-md '}
                />

                <div className={'flex w-full items-center justify-between gap-2 pt-6'}>
                    <Button data-testid="next-button" onClick={handleCancel} className={' flex-1 !border-gray-300 !bg-white !text-black-1 hover:!bg-gray-50'} variant={'outline'} isLoading={isLoading} disabled={isLoading} type="button">
                        {t('cancel')}
                    </Button>
                    <Button data-testid="next-button" className={' flex-1'} variant={'solid'} isLoading={isLoading} disabled={!newProjectObj.name} type="submit">
                        {t('next', { ns: 'project' })}
                    </Button>
                </div>
            </form>
        </div>
    );
}
