import { RoleType } from '@app/models/enums/role';

export const isGreaterThanOrSameRole = (userRole: RoleType, otherMemberRole: RoleType) => {
    return getRoleIndex(userRole) <= getRoleIndex(otherMemberRole);
};

export const isGreaterThanOtherRole = (useRole: RoleType, otherMemberRole: RoleType) => {
    return getRoleIndex(useRole) < getRoleIndex(otherMemberRole);
};

function getRoleIndex(role: RoleType) {
    switch (role) {
        case RoleType.OWNER:
            return 0;
        case RoleType.ADMIN:
            return 1;
        case RoleType.PROJECT_ADMIN:
            return 2;
        case RoleType.EDITOR:
            return 3;
        case RoleType.REVIEWER:
            return 4;
        case RoleType.MEMBER:
            return 5;
        case RoleType.VIEWER:
            return 6;
        default:
            return -1;
    }
}

// Function to convert a string to RoleType enum
export const stringToRoleType = (roleString: string): RoleType => {
    const roleValues = Object.values(RoleType) as string[];
    if (roleValues.includes(roleString)) {
        return roleString as RoleType;
    }
    return RoleType.VIEWER; // Return undefined when the roleString does not match any enum value
};
