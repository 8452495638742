import React from 'react';
import { ProjectListResponse } from '../my-organizations-sidebar/project-list-view';
import ProjectRoleDropdown from './project-role-dropdown';
import { Roles } from '@app/constants/Constants';
import { RoleType } from '@app/models/enums/role';
import { useAppSelector } from '@app/store/hooks';
import { selectAssignedProjectInfo } from '@app/store/project/assign-projects-slice';

function getBackgroundColorBasedOnRole(role: string) {
    const Role = role?.toLowerCase();
    switch (Role) {
        case Roles.EDITOR:
            return 'bg-[#F3F8FE] border-[#A7C8F8] text-[#0764EB]';
        case Roles.ADMIN:
            return 'bg-[#FEF5F5] border-[#FCD8D5] text-[#F2695C]';
        case Roles.VIEWER:
            return 'bg-[#FEFAF4] border-[#F7D3A0] text-[#EA8C07]';
        case Roles.REVIEWER:
            return 'bg-[#FEF5F9] border-[#F9B6D2] text-[#EC166F]';
        default:
            return 'bg-gray-200';
    }
}

interface Props {
    row: ProjectListResponse;
    userId: string;
}

function ProjectUserRole({ row, userId }: Props) {
    const assignProject = useAppSelector(selectAssignedProjectInfo);

    const selectedProject = assignProject.selectedRows.find((selected) => selected.projectId === row.projectId);
    const role = selectedProject?.role ?? row.userRole ?? RoleType.VIEWER;

    const isSelected = assignProject.selectedRows.some((selected) => selected.projectId === row.projectId);

    return (
        <>
            {isSelected ? (
                <ProjectRoleDropdown isAssignProject id={`member-${userId}`} currentRole={role} userId={userId} assignProjects={[{ projectId: row.projectId, role }]} getBackgroundColor={getBackgroundColorBasedOnRole} />
            ) : (
                <span>{row.userRole}</span>
            )}
        </>
    );
}

export default ProjectUserRole;
