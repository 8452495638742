import React from 'react';

function OfficeDocumentIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="102" height="96" viewBox="0 0 102 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_6138_55532)">
                <path d="M12.8535 47.4138L19.5783 33.4502H83.0002L89.1453 47.4138V79.2113H12.8535V47.4138Z" fill="black" />
            </g>
            <path d="M33.9336 15.7537L32.2363 20.2449H45.1362L43.4389 15.7537C43.0417 14.7022 42.2296 14.0381 41.3411 14.0381H36.0312C35.143 14.0381 34.3309 14.7022 33.9336 15.7537Z" fill="#E2E4EB" />
            <path
                d="M69.7632 41.9691H32.2363C31.5886 41.9691 31.0635 41.2743 31.0635 40.4173V18.6934C31.0635 17.8365 31.5886 17.1416 32.2363 17.1416H69.7633C70.411 17.1416 70.9361 17.8365 70.9361 18.6934V40.4175C70.936 41.2744 70.4108 41.9691 69.7632 41.9691Z"
                fill="#E2E4EB"
            />
            <path
                d="M72.8901 43.5211H33.6525C33.0506 43.5211 32.5625 42.8262 32.5625 41.9693V20.2452C32.5625 19.3882 33.0506 18.6934 33.6525 18.6934H72.8899C73.4918 18.6934 73.9799 19.3882 73.9799 20.2452V41.9693C73.9799 42.8262 73.492 43.5211 72.8901 43.5211Z"
                fill="#EFF2FA"
            />
            <path
                d="M95.6094 45.9219L88.6849 33.4574C88.4112 32.9647 87.8919 32.6592 87.3285 32.6592H14.6716C14.1081 32.6592 13.589 32.9647 13.3153 33.4574L6.39059 45.9219C6.13447 46.383 6 46.9015 6 47.429V54.3835H96V47.429C96 46.9015 95.8655 46.383 95.6094 45.9219Z"
                fill="url(#paint0_linear_6138_55532)"
            />
            <path d="M25.3151 20.409L23.0693 24.9002H42.0563L39.8106 20.409C39.285 19.3575 38.2101 18.6934 37.0348 18.6934H28.0909C26.9154 18.6934 25.8409 19.3575 25.3151 20.409Z" fill="#D9E2FF" />
            <path
                d="M78.9316 46.6246H23.0694C22.2124 46.6246 21.5176 45.9297 21.5176 45.0728V23.3487C21.5176 22.4917 22.2124 21.7969 23.0694 21.7969H78.9314C79.7884 21.7969 80.4832 22.4917 80.4832 23.3487V45.0728C80.4832 45.9297 79.7885 46.6246 78.9316 46.6246Z"
                fill="#D9E2FF"
            />
            <path
                d="M76.1969 49.7281H28.9069C28.254 49.7281 27.7246 49.0332 27.7246 48.1763V26.4522C27.7246 25.5953 28.254 24.9004 28.9069 24.9004H76.1968C76.8497 24.9004 77.3791 25.5953 77.3791 26.4522V48.1763C77.3791 49.0332 76.8498 49.7281 76.1969 49.7281Z"
                fill="#EFF2FA"
            />
            <path d="M22.2116 26.616L19.9658 31.1072H43.2417L40.9959 26.616C40.4704 25.5645 39.3958 24.9004 38.2202 24.9004H24.9875C23.8117 24.9004 22.7372 25.5645 22.2116 26.616Z" fill="#C2D2FD" />
            <path
                d="M82.0349 52.8316H19.9659C19.1089 52.8316 18.4141 52.1367 18.4141 51.2798V29.5557C18.4141 28.6988 19.1089 28.0039 19.9659 28.0039H82.0347C82.8917 28.0039 83.5865 28.6988 83.5865 29.5557V51.2798C83.5865 52.1369 82.8919 52.8316 82.0349 52.8316Z"
                fill="#C2D2FD"
            />
            <path
                d="M85.1382 55.9349H16.8623C16.0054 55.9349 15.3105 55.2401 15.3105 54.3831V32.6592C15.3105 31.8023 16.0054 31.1074 16.8623 31.1074H85.1382C85.9952 31.1074 86.69 31.8023 86.69 32.6592V54.3833C86.6899 55.2403 85.9952 55.9349 85.1382 55.9349Z"
                fill="url(#paint1_linear_6138_55532)"
            />
            <g filter="url(#filter1_i_6138_55532)">
                <path
                    d="M94.4482 46.625H69.0279C68.4403 46.625 67.903 46.9571 67.6402 47.4826L65.8235 51.1162C65.2979 52.1677 64.223 52.8318 63.0477 52.8318H38.9527C37.7772 52.8318 36.7025 52.1677 36.1769 51.1162L34.3602 47.4826C34.0974 46.9571 33.56 46.625 32.9724 46.625H7.5518C6.69486 46.625 6 47.3199 6 48.1768V77.6595C6 78.5165 6.69486 79.2113 7.5518 79.2113H94.4484C95.3053 79.2113 96.0002 78.5165 96.0002 77.6595V48.1766C96 47.3197 95.3051 46.625 94.4482 46.625Z"
                    fill="url(#paint2_linear_6138_55532)"
                />
            </g>
            <path
                d="M63.4141 43.5214H82.0348C82.8917 43.5214 83.5866 42.8265 83.5866 41.9696V35.7627C83.5866 34.9058 82.8917 34.2109 82.0348 34.2109H63.4141C62.5572 34.2109 61.8623 34.9058 61.8623 35.7627V41.9696C61.8623 42.8265 62.5572 43.5214 63.4141 43.5214Z"
                fill="#EFF2FA"
            />
            <path
                d="M72.7245 37.3145H66.5176C65.6607 37.3145 64.9658 38.0093 64.9658 38.8663C64.9658 39.7232 65.6607 40.418 66.5176 40.418H72.7245C73.5814 40.418 74.2763 39.7232 74.2763 38.8663C74.2763 38.0091 73.5814 37.3145 72.7245 37.3145Z"
                fill="#707487"
            />
            <path
                d="M78.9317 37.3145H77.3799C76.523 37.3145 75.8281 38.0093 75.8281 38.8663C75.8281 39.7232 76.523 40.418 77.3799 40.418H78.9317C79.7887 40.418 80.4835 39.7232 80.4835 38.8663C80.4833 38.0093 79.7887 37.3145 78.9317 37.3145Z"
                fill="#8F96AC"
            />
            <defs>
                <filter id="filter0_d_6138_55532" x="0.253515" y="24.4502" width="101.492" height="70.9607" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.6" />
                    <feGaussianBlur stdDeviation="6.3" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.239624 0 0 0 0 0.229783 0 0 0 0 0.34 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6138_55532" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6138_55532" result="shape" />
                </filter>
                <filter id="filter1_i_6138_55532" x="6" y="46.625" width="90" height="36.1859" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.6" />
                    <feGaussianBlur stdDeviation="9" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.392157 0 0 0 0 0.921569 0 0 0 0.26 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6138_55532" />
                </filter>
                <linearGradient id="paint0_linear_6138_55532" x1="51" y1="32.6592" x2="51" y2="54.3835" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#A5C3F4" />
                    <stop offset="1" stop-color="#6EA2F6" />
                </linearGradient>
                <linearGradient id="paint1_linear_6138_55532" x1="51.0003" y1="31.1074" x2="51.0003" y2="41.8068" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5B7CE0" />
                    <stop offset="1" stop-color="#5277D2" />
                </linearGradient>
                <linearGradient id="paint2_linear_6138_55532" x1="51.0001" y1="46.625" x2="51.0001" y2="79.2113" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D3E4FF" />
                    <stop offset="1" stop-color="#CDE0FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default OfficeDocumentIcon;
