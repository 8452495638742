import React, { useEffect, useState } from 'react';
import MultilineArrayInput from '../cards/multiline-input';
import { useTranslation } from 'next-i18next';
import { useGetWordListTemplateQuery, useUpdateWordListTemplateMutation } from '@app/store/organization/api';
import { useRouter } from 'next/router';
import { organizationWordTemplateRequest } from '@app/store/organization/types';
import Button from '../ui/button';
import { useModal } from '../modal-views/context';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';

function AddDetailsToOrganizationWordListTemplate({ templateId }: { templateId: string }) {
    const { t } = useTranslation(['common', 'organization']);
    const [wordLists, setWordLists] = useState<Array<string>>([]);
    const router = useRouter();
    const { closeModal } = useModal();

    const {
        data: wordlistTemplate,
        isLoading: isWordlistTemplateLoading,
        isSuccess: isWordlistTemplateLoaded
    } = useGetWordListTemplateQuery({
        organizationId: router.query.organizationId as string,
        wordListTemplateId: templateId
    });

    const [updateWordListTemplate, { isLoading: isUpdating }] = useUpdateWordListTemplateMutation();

    useEffect(() => {
        if (isWordlistTemplateLoaded) {
            setWordLists(wordlistTemplate?.words || []);
        }
    }, [isWordlistTemplateLoaded, wordlistTemplate]);

    const handleSaveChanges = async () => {
        const wordListRequestBody: organizationWordTemplateRequest = {
            organizationId: router.query.organizationId as string,
            templateId: templateId,
            wordListName: wordlistTemplate?.wordListName as string,
            words: wordLists
        };
        try {
            await updateWordListTemplate(wordListRequestBody).unwrap();
            toast.success(`${t('toast.wordListTemplatesaved')}`, { toastId: ToastId.WORDLIST_SAVED_TOAST });
            closeModal();
        } catch (err) {
            console.error(err);
            toast.error(`${t('toast.wordListTemplateUpdatingError')}`, { toastId: ToastId.WORDLIST_ERROR_TOAST });
        }
    };

    const handleCancel = (e: any) => {
        e.preventDefault();
        closeModal();
    };

    console.log(wordlistTemplate);

    return (
        <div className="flex w-[90vw] max-w-xl flex-col p-4">
            <h1 className={'pb-2 text-xl font-bold'}>{wordlistTemplate?.wordListName}</h1>
            <MultilineArrayInput value={wordLists?.join('\n')} setValue={(value: string[]) => setWordLists(value)} isDisabled={isWordlistTemplateLoading} inputPlaceholder={t('enterWords', { ns: 'organization' })} />
            <div className={'ml-auto flex w-1/2 items-center justify-end gap-2 pt-2'}>
                <Button
                    data-testid="cancel-button"
                    onClick={handleCancel}
                    className={'flex-1 !border-gray-300 !bg-white !text-black-1 hover:!bg-gray-50'}
                    variant={'outline'}
                    isLoading={isWordlistTemplateLoading}
                    disabled={isWordlistTemplateLoading}
                    type="button"
                >
                    {t('cancel')}
                </Button>
                <Button data-testid="save-button" className={'flex-1'} variant={'solid'} isLoading={isUpdating} disabled={isUpdating} onClick={handleSaveChanges}>
                    {t('saveChanges', { ns: 'organization' })}
                </Button>
            </div>
        </div>
    );
}

export default AddDetailsToOrganizationWordListTemplate;
