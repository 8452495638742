import DataTable from 'react-data-table-component';
import {noHoverTableStyles } from '@app/components/datatable/pdf-table/datatable-styles';
import React from 'react';
import cn from 'classnames';
import { MemberDto, MemberInvitationDto } from '@app/store/project/types';
import ProfileImage from '@app/components/auth/profile-image';
import { useAppSelector } from '@app/store/hooks';
import { selectAuth } from '@app/store/auth/authSlice';
import { useModal } from '@app/components/modal-views/context';
import MemberRoleDropdown from '@app/components/ui/member-role-dropdown';
import { capitalize } from '@app/utils/stringUtils';
import { isGreaterThanOrSameRole, isGreaterThanOtherRole } from '@app/utils/roleUtils';
import { selectActiveOrganization, selectActiveProject } from '@app/store/active/slice';
import { useRouter } from 'next/router';
import { RoleType } from '@app/models/enums/role';
import { useTranslation } from 'next-i18next';
import { InvitationSendBodyType } from '@app/store/invitation/types';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import { useSendInvitationToMemberFromProjectMutation } from '@app/store/project/api';
import { useSendInvitationToMemberFromOrganizationMutation } from '@app/store/organization/api';
import MemberActionDropdown from '@app/components/datatable/member-table/action-dropdown';
import { Roles } from '@app/constants/Constants';
import PlusIcon from '@app/components/icons/plus-icon';
import ActionDeleteIcon from '@app/components/icons/action-delete';

interface Props {
    data?: Array<MemberDto | MemberInvitationDto>;
    handleDeletedMembers: (userId: string) => void;
    handleRoleChange?: (member: MemberDto | MemberInvitationDto, role: string) => void;
    className?: string;
    isAddMember: boolean;
    handleRemoveInvitation?: (token: string) => void;
    origin: 'organization' | 'project';
    displayActionMenu?: boolean;
}

function Index({ data, handleDeletedMembers, handleRoleChange, handleRemoveInvitation, className, isAddMember, origin, displayActionMenu = true }: Props) {
    const auth = useAppSelector(selectAuth);
    const { openModal } = useModal();
    const activeProject = useAppSelector(selectActiveProject);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const router = useRouter();
    const [inviteUserToProject] = useSendInvitationToMemberFromProjectMutation();
    const [inviteUserToOrganization] = useSendInvitationToMemberFromOrganizationMutation();
    const { t } = useTranslation(['common', 'organization']);

   const handleViewMemberDetais = (userId: string, name: string, email: string, picture: any) => {
         router.push(`/${activeOrganization?.id}/members/${userId}/view-member-details?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&picture=${encodeURIComponent(picture)}`);
   };

    const successToast = () => {
        toast(`${t('toast.userHasBeenInvited')}`, { type: 'success', toastId: ToastId.INVITATION_SUCCESS_TOAST });
    };
    const errorToast = () => {
        toast(`${t('toast.invitationSendFailed')}`, { type: 'error', toastId: ToastId.INVITATION_ERROR_TOAST });
    };
    const handleRoleSelection = (row: MemberDto | MemberInvitationDto, role: string) => {
        if (!!handleRoleChange) {
            if (!isAddMember || 'token' in row)
                openModal('ROLE', {
                    member: row,
                    newRole: role as RoleType,
                    //@ts-ignore
                    handleActionButton: () => handleRoleChange(row, role)
                });
            else handleRoleChange(row, role);
        }
    };

    const handleInviteMember = async (invitation: MemberInvitationDto, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const requestBody: InvitationSendBodyType = {
            organizationId: router.query.organizationId as string,
            email: invitation.email,
            role: invitation.role,
            projectId: router.query.projectId as string
        };
        let response;
        if (router.query.projectId) {
            response = await inviteUserToProject(requestBody);
        } else {
            response = await inviteUserToOrganization(requestBody);
        }
        if ('data' in response) {
            successToast();
        }
        if ('error' in response) {
            errorToast();
        }
    };

    function disableRoleMutation(member: MemberDto) {
        const isOwner = (activeOrganization?.userRole.toLowerCase() as RoleType) === RoleType.OWNER;
        const isOrganizationProjectAdminOrAdminOrOwner = isGreaterThanOrSameRole(activeOrganization?.userRole.toLowerCase() as RoleType, RoleType.PROJECT_ADMIN);
        const isSameUser = auth.email === member.userInfo.email;
        const activeRole = router.query.projectId ? (activeProject?.userRole.toLowerCase() as RoleType) : (activeOrganization?.userRole.toLowerCase() as RoleType);
        const isGreaterRole = isGreaterThanOtherRole(activeRole, member.role.toLowerCase() as RoleType);
        if ((router.query.projectId === null && isOwner) || (router.query.projectId != null && isOrganizationProjectAdminOrAdminOrOwner && !isSameUser) || isAddMember) {
            return false;
        }
        return isSameUser || !isGreaterRole;
    }

    function isInvitationRoleUpdateEnable(invitedMember: MemberInvitationDto) {
        const isOrganizationAdminOrOwner = isGreaterThanOrSameRole(activeOrganization?.userRole.toLowerCase() as RoleType, RoleType.ADMIN);
        const activeRole = router.query.projectId ? (activeProject?.userRole.toLowerCase() as RoleType) : (activeOrganization?.userRole.toLowerCase() as RoleType);
        return activeRole === RoleType.MEMBER || invitedMember.invitedBy === auth.userId || isOrganizationAdminOrOwner;
    }

    const handleRemoveMember = (member: MemberDto) => {
        if (!isAddMember) {
            openModal('REMOVE_MEMBER', {
                member,
                handleMemberDeletion: () => handleDeletedMembers(member.userInfo.userId)
            });
            return;
        }
        handleDeletedMembers(member.userInfo.userId);
    };

    const handleRemoveMemberInvitation = (row: MemberInvitationDto) => {
        openModal('REMOVE_INVITATION', {
            email: row.email,
            //@ts-ignore
            handleRemoveInvitation: () => handleRemoveInvitation(row.token)
        });
    };

    function getBackgroundColorBasedOnRole(role: string) {
        const Role = role?.toLowerCase();
        switch (Role) {
            case Roles.EDITOR:
                return 'bg-[#F3F8FE] border-[#A7C8F8] text-[#0764EB]';
            case Roles.ADMIN:
                return 'bg-[#FEF5F5] border-[#FCD8D5] text-[#F2695C]';
            case Roles.VIEWER:
                return 'bg-[#FEFAF4] border-[#F7D3A0] text-[#EA8C07]';
            case Roles.REVIEWER:
                return 'bg-[#FEF5F9] border-[#F9B6D2] text-[#EC166F]';
            default:
                return 'bg-gray-200';
        }
    }

    function getValidRoles(row: MemberDto) {
        return disableRoleMutation(row) ? (
            <p className={'p2 rounded-full border-[1px] px-2 text-sm font-medium ' + getBackgroundColorBasedOnRole(row.role)}>{capitalize(t(`roleType.${row.role.toLowerCase()}`))}</p>
        ) : (
            <MemberRoleDropdown
                id={`member-${row.userInfo.email}`}
                member={row}
                handleUpdateRole={(role: string) => handleRoleSelection(row, role)}
                handleRemoveMember={() => handleRemoveMember(row)}
                currentRole={row.role}
                getBackgroundColor={getBackgroundColorBasedOnRole}
            />
        );
    }

    function getValidInvitationRoles(row: MemberInvitationDto) {
        if (isInvitationRoleUpdateEnable(row)) {
            if (row.status.toLowerCase() === 'expired') {
                return (
                    <div className="flex flex-row gap-2 px-2">
                        <p className="p2  text-gray-500">{t('invitationExpired')}</p>
                        <button onClick={(event) => handleInviteMember(row, event)} className="p2 cursor-pointer px-2  text-blue-500">
                            Resend
                        </button>
                    </div>
                );
            }
            return (
                <MemberRoleDropdown
                    id={`invitation-${row.email}`}
                    isInvitation={true}
                    handleUpdateRole={(role: string) => handleRoleSelection(row, role)}
                    handleRemoveMember={() => {
                        openModal('REMOVE_INVITATION', {
                            email: row.email,
                            //@ts-ignore
                            handleRemoveInvitation: () => handleRemoveInvitation(row.token)
                        });
                    }}
                    currentRole={row.role}
                    getBackgroundColor={() => {
                        return getBackgroundColorBasedOnRole(row.role);
                    }}
                />
            );
        }
        return (
            <p className="p2 px-2 text-black-600">
                {t('invitedAs')} {capitalize(t(`roleType.${row.role.toLowerCase()}`))}
            </p>
        );
    }

    const columns: Array<any> = [
        {
            name: <div className={'ml-20 text-xs font-bold text-black-600'}>{t('user').toUpperCase()}</div>,
            selector: (row: MemberDto | MemberInvitationDto) => (
                <div className={'ml-6 flex items-center gap-4'}>
                    <ProfileImage
                        // style={getProfileAvatarColor(project.projectName)}
                        name={'userInfo' in row ? (row?.userInfo.name ?? row?.userInfo?.email) : row?.email}
                        image={'userInfo' in row ? row?.userInfo.picture : ''}
                        className={'h-10 w-10 rounded-lg'}
                    ></ProfileImage>
                    <div className={'flex flex-col gap-2'}>
                        {'userInfo' in row && (
                            <div className={'flex items-center'}>
                                <p
                                    data-testid="member-name"
                                    className={cn('mr-1 text-[13px] font-semibold', { 'cursor-pointer': origin === 'organization' })}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const userId = 'userInfo' in row ? row.userInfo.userId : row;
                                        if (userId && origin === 'organization' && !['owner', 'admin'].includes(row.role?.toLowerCase() as string)) {
                                            handleViewMemberDetais(userId,row.userInfo.name, row.userInfo.email, row.userInfo.picture);
                                        }
                                    }}
                                >
                                    {row.userInfo.name}
                                </p>
                                {row?.isSupportMember && <div className={'items-center overflow-x-scroll rounded-md bg-blue-100 p-1 text-[11px] text-blue-500'}>{t('support', { ns: 'organization' })}</div>}
                            </div>
                        )}
                        <p data-testid="member-email" className={'text-black-600'}>
                            {'userInfo' in row ? row.userInfo.email : row.email}
                        </p>
                    </div>
                </div>
            ),
            width: '250px'
        },
        {
            name: <div className={'ml-2 text-xs font-bold text-black-600'}>{t('role').toUpperCase()}</div>,
            selector: (row: MemberDto | MemberInvitationDto) => <>{'userInfo' in row ? getValidRoles(row as MemberDto) : getValidInvitationRoles(row)}</>,
            width: '220px'
        },
        {
            name: <div className={cn('text-xs font-bold text-black-600')}>{t('action', { ns: 'organization' }).toUpperCase()}</div>,
            selector: (row: any) => {
                const activeRole = router.query.projectId ? (activeProject?.userRole.toLowerCase() as RoleType) : (activeOrganization?.userRole.toLowerCase() as RoleType);
                const rowRole = row.role?.toLowerCase() as RoleType;
                const isSameUser = auth.email === (row as MemberDto)?.userInfo?.email;
                const isGreaterOrSameRole = isGreaterThanOrSameRole(activeRole, rowRole);
                const isGreaterRole = isGreaterThanOtherRole(activeRole, rowRole);
                const canDelete = origin === 'organization' ? !isSameUser && isGreaterRole : !isSameUser && isGreaterOrSameRole;

                const showMemberActionDropdown = origin === 'organization' && (row as MemberInvitationDto).email === undefined;
                if (!canDelete && !showMemberActionDropdown) return null;

                return (
                    <div className="flex items-center gap-2">
                        {canDelete && (
                            <div
                                className="flex h-[2.125rem] w-[2.125rem] cursor-pointer items-center justify-center rounded-lg border border-gray-300 bg-gray-50 p-2"
                                onClick={() => ((row as MemberInvitationDto).email === undefined ? handleRemoveMember(row as MemberDto) : handleRemoveMemberInvitation(row as MemberInvitationDto))}
                            >
                                <ActionDeleteIcon />
                            </div>
                        )}

                        {showMemberActionDropdown && (
                            <div className="flex h-[2.125rem] w-[2.125rem] items-center justify-center rounded-lg border border-gray-300 bg-gray-50 p-2">
                                <MemberActionDropdown memberInfo={row} />
                            </div>
                        )}
                    </div>
                );
            },
            width: '120px'
        }
    ];

    return (
        <div className={cn('mt-[14px] rounded-b-xl bg-white', className)}>
            {data && data.length > 0 ? (
                <DataTable
                    className={cn('h-full min-h-full', { 'h-calc-380': !isAddMember }, { 'rounded-lg border-[2px] border-gray-200': isAddMember })}
                    fixedHeader
                    columns={columns}
                    data={data || []}
                    selectableRows={false}
                    customStyles={noHoverTableStyles}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    onRowClicked={(row: MemberDto | MemberInvitationDto) => {
                        if ('userInfo' in row && row.userInfo?.userId && origin === 'organization' && !['owner', 'admin'].includes(row.role?.toLowerCase() as string)) {
                            handleViewMemberDetais(row.userInfo.userId, row.userInfo.name, row.userInfo.email, row.userInfo.picture);
                        }
                    }}
                />
            ) : (
                <div className="flex h-[150px] flex-col items-center justify-center gap-5 pb-3">
                    <PlusIcon />
                    <p className="w-2/3 text-center text-sm font-semibold text-gray-900">{t('pleaseAddOrInviteMembers')}</p>
                </div>
            )}
        </div>
    );
}

export default Index;
