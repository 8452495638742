import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from '@app/store/store';
import { projectStateInterface } from '@app/store/project/types';
import { ProjectListResponse } from '@app/components/my-organizations-sidebar/project-list-view';

export const initialProjectState: projectStateInterface = {
    query: undefined,
    page: 1,
    size: 10,
    toggleCleared: false,
    selectedRows: [],
    resetPagination: false
};

export const projectSlice = createSlice({
    name: 'projectSlice',
    initialState: initialProjectState,
    reducers: {
        setQuery: (state, action) => {
            return {
                ...state,
                query: action.payload,
                filterBy: undefined,
                toggleCleared: !state.toggleCleared,
                selectedRows: [],
                page: 1,
                resetPagination: !state.resetPagination
            };
        },
        setPagination: (state, action) => {
            return {
                ...state,
                page: action.payload.page,
                size: action.payload.size ?? state.size,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        resetProjectSliceAndSetQuery: (state, action) => {
            return {
                ...state,
                filterBy: undefined,
                query: action.payload,
                resetPagination: !state.resetPagination,
                page: 1,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        resetQuery: (state) => {
            return {
                ...state,
                query: undefined,
                filterBy: undefined
            };
        },
        setToggleCleared: (state, action) => {
            return {
                ...state,
                toggleCleared: action.payload,
                selectedRows: []
            };
        },
        setSelectedRows: (state, action) => {
            return {
                ...state,
                selectedRows: action.payload
            };
        },
        resetProjectSlice: (state) => {
            return {
                ...state,
                query: undefined,
                filterBy: undefined,
                page: 1,
                resetPagination: !state.resetPagination,
                toggleCleared: !state.toggleCleared,
                selectedRows: []
            };
        },
        updateSelectedRowRole: (state, action) => {
            const updatedProjects = action.payload;
            state.selectedRows = state.selectedRows.map((project) => {
                const updatedProject = updatedProjects.find((p: ProjectListResponse) => p.projectId === project.projectId);
                return updatedProject ? { ...project, userRole: updatedProject.role } : project;
            });
        }
    }
});

const projectReducer = persistReducer(
    {
        key: 'rtk:projectSlice',
        storage
    },
    projectSlice.reducer
);

const reducerObj = { reducerPath: projectSlice.name, reducer: projectReducer };

export const selectProjectInfo = (state: RootState) => state.projectSlice;

export const { setSelectedRows, setPagination, setToggleCleared, resetProjectSliceAndSetQuery, resetQuery, resetProjectSlice, setQuery, updateSelectedRowRole } = projectSlice.actions;

export default reducerObj;
