import React from 'react';
import { AppProps } from 'next/app';
import { NextPageWithLayout } from '@app/types';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { appWithTranslation } from 'next-i18next';
import '@uppy/core/dist/style.min.css';
import '@app/assets/css/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import createEmotionCache from '@app/configs/createEmotionCache';
import NextNProgress from '@app/components/ui/nprogress';
import { ToastContainer } from 'react-toastify';
import { lightTheme } from '@app/configs/muiTheme';
import { persistor, store } from '@app/store/store';
import { Provider } from 'react-redux';
import ModalContainer from '@app/components/modal-views/container';
import { PersistGate } from 'redux-persist/integration/react';
import FullScreenLoader from '@app/components/ui/fullscreen-loader';
import globalConstants from '@app/constants/Global';
import { NextSeo } from 'next-seo';
import environments from '@app/configs/environments';
import { IDashboardProps } from '@app/pages/[organizationId]/project/[projectId]/dashboard';
import { ProjectListResponse } from '@app/components/my-organizations-sidebar/project-list-view';
import AuthDispatcher from '@app/components/auth/auth-dispatcher';

const clientSideEmotionCache = createEmotionCache();
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    pageProps: IDashboardProps | any;
};

function MainApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? ((page: any) => page);
    let title = globalConstants.title || globalConstants.socialPreview.title;
    let description = globalConstants.socialPreview.desc;
    let url = globalConstants.socialPreview.url;
    let imageUrl = globalConstants.socialPreview.image;
    const project: ProjectListResponse | null = pageProps?.project;
    if (project) {
        title = project?.projectName ?? title;
        description = project?.projectDescription ?? description;
    }
    const organizationId: string | null = pageProps?.organizationId;
    if (organizationId) {
        imageUrl = project?.projectAvatar ?? 'https://s3.eu-central-1.wasabisys.com/pdfeditor/cloud_saas/dashboard-new.png';
    }
    return (
        <ThemeProvider theme={lightTheme}>
            <CacheProvider value={emotionCache}>
                <NextSeo
                    title={title || globalConstants.socialPreview.title}
                    description={description}
                    noindex={!environments.IS_IN_PRODUCTION_MODE}
                    nofollow={!environments.IS_IN_PRODUCTION_MODE}
                    openGraph={{
                        type: 'website',
                        locale: 'en_IE',
                        url,
                        site_name: title || globalConstants.appName,
                        description: description,
                        title,
                        images: [
                            {
                                url: imageUrl,
                                alt: title ?? 'PDF Editor'
                            }
                        ]
                    }}
                    twitter={{
                        handle: globalConstants.twitterHandle,
                        site: url,
                        cardType: 'summary_large_image'
                    }}
                />
                <NextNProgress color="#0764EB" startPosition={0} stopDelayMs={400} height={2} options={{ easing: 'ease' }} />
                <ToastContainer position="bottom-center" autoClose={3000} progressStyle={{ top: 0, bottom: 'auto !important' }} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover={false} theme="light" />
                <Provider store={store}>
                    <PersistGate loading={<FullScreenLoader />} persistor={persistor}>
                        <AuthDispatcher>{getLayout(<Component {...pageProps} key={router.asPath} />)}</AuthDispatcher>
                        <ModalContainer />
                    </PersistGate>
                </Provider>
            </CacheProvider>
        </ThemeProvider>
    );
}

export default appWithTranslation(MainApp);
