import React from 'react';
import cn from 'classnames';
import EmptyDocumentIcon from '@app/assets/image/empty-document-icon.png';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

interface IEmptyRecoverPdfProps {
    className?: string;
    isAssignProject?: boolean;
    isWordListTemplate?: boolean;
}

function EmptyProject({ className, isAssignProject, isWordListTemplate }: IEmptyRecoverPdfProps) {
    const { t } = useTranslation(['organization', 'common']);

    const message = isWordListTemplate ? t('noWordListTemplate') : isAssignProject ? t('noProjects') : t('noAssignedProjects');

    return (
        <div className={cn('p2 !z-0 flex h-full w-full flex-1 flex-col items-center', className)}>
            <div className={cn('h-40 w-40', { 'h-20 w-20': isWordListTemplate })}>
                <Image src={EmptyDocumentIcon} objectFit={'fill'} alt="logo" />
            </div>
            <p className="!z-0 w-full max-w-full py-3 text-center text-sm font-semibold text-gray-900">{message}</p>
        </div>
    );
}

export default EmptyProject;
