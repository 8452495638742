import React from 'react';

interface HeadingsProps {
    title: string;
    description: string;
    children?: any;
}

function OrganizationHeadings({ children, title, description }: HeadingsProps) {
    return (
        <>
            <h1 className={'mt-6 text-xl font-bold'}>{title}</h1>
            <p className="text-sm font-normal text-gray-500">{description}</p>

            <div className={'flex items-center justify-between gap-2'}>{children}</div>
        </>
    );
}

export default OrganizationHeadings;
