function GalleryIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.75492 6.18853L10.8197 5.17773V26.4564L8.67389 26.9082C6.08988 27.4522 3.56302 25.7678 3.07098 23.1734L0.898224 11.7171C0.405419 9.11868 2.14616 6.62332 4.75492 6.18853Z" fill="white" stroke="#004AAD" stroke-width="1.57143" />
            <path d="M39.2451 6.18853L33.1803 5.17773V26.4564L35.3261 26.9082C37.9101 27.4522 40.437 25.7678 40.929 23.1734L43.1018 11.7171C43.5946 9.11868 41.8538 6.62332 39.2451 6.18853Z" fill="white" stroke="#004AAD" stroke-width="1.57143" />
            <g filter="url(#filter0_dd_6137_55456)">
                <rect x="10.0898" y="0.848633" width="23.8033" height="28.1311" rx="4.71429" fill="white" />
                <rect x="10.8756" y="1.63435" width="22.2318" height="26.5597" rx="3.92857" stroke="#004AAD" stroke-width="1.57143" />
            </g>
            <path
                d="M29.1788 28.1929H14.8041C12.6344 28.1929 10.8756 26.434 10.8756 24.2643L10.8756 23.0941L18.5122 15.0013L24.2568 21.612C24.8859 22.3359 26.0112 22.3324 26.6357 21.6044L29.6934 18.04L33.1074 21.7136V24.2643C33.1074 26.434 31.3485 28.1929 29.1788 28.1929Z"
                fill="#FBFCFE"
                stroke="#004AAD"
                stroke-width="1.57143"
            />
            <path
                d="M27.3467 9.86407C27.3467 11.2228 26.2453 12.3243 24.8865 12.3243C23.5278 12.3243 22.4263 11.2228 22.4263 9.86407C22.4263 8.50534 23.5278 7.40388 24.8865 7.40388C26.2453 7.40388 27.3467 8.50534 27.3467 9.86407Z"
                fill="#FBFCFE"
                stroke="#004AAD"
                stroke-width="1.57143"
            />
            <defs>
                <filter id="filter0_dd_6137_55456" x="7.7327" y="0.848633" width="28.518" height="33.6309" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1.57143" />
                    <feGaussianBlur stdDeviation="0.785714" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6137_55456" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="3.14286" />
                    <feGaussianBlur stdDeviation="1.17857" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_6137_55456" result="effect2_dropShadow_6137_55456" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6137_55456" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default GalleryIcon;
