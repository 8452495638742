import OfficeDocumentIcon from '@app/components/icons/office-document';
import { Divider, Input } from '@mui/material';
import React from 'react';
import Button from '../button';
import ImagesIcon from '@app/components/icons/gallery-icon';
import { useTranslation } from 'next-i18next';

export enum Status {
    'CONVERSION' = 'CONVERSION',
    'OCR' = 'OCR',
    'ANNOTATION' = 'ANNOTATION',
    'ANONYMIZATION' = 'ANONYMIZATION'
}

export interface UploadRequest {
    file: File | null;
    status: Status;
}

export interface IUploadStatus {
    value: Status;
    label: string;
}

interface ActionSupportCardProps {
    progress?: number;
    uploadStatusOptions: IUploadStatus[];
    chosenUploadStatus: Status;
    isUploading: boolean;
    handleUploadStatusSelectionChange: (value: string) => void;
    handleSubmit?: () => Promise<void>;
    handleUploadButton?: () => void;
    handleUploadFile: (files: FileList | null) => void;
    handleCancel?: () => void;
    handleDialogClose?: () => void;
    shouldSubmitBeDisabled: boolean;
}

export default function ActionSupportCard({
    progress = 0,
    uploadStatusOptions,
    chosenUploadStatus,
    handleUploadStatusSelectionChange,
    isUploading,
    handleSubmit,
    handleUploadButton,
    handleUploadFile,
    handleCancel,
    handleDialogClose,
    shouldSubmitBeDisabled
}: ActionSupportCardProps) {
    const { t } = useTranslation(['modal']);

    return (
        <div className="h-full w-full rounded-xl bg-white p-1 shadow-lg">
            <div className="z-[2000px] h-full w-full rounded-lg border border-gray-300">
                <div className="flex h-[138px] w-full flex-col items-center  justify-center rounded-t-lg bg-blue-200/50 p-5">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full bg-transparent">
                        <OfficeDocumentIcon />
                    </div>
                </div>
                <div className="-pt-2 rounded-t-lg p-4">
                    <div>
                        <h2 className="text-center text-base font-semibold text-gray-700">{t('chooseAction', { ns: 'modal' })}</h2>

                        <div className="z-50 mt-4 flex justify-center space-x-2">
                            {uploadStatusOptions.map(({ value, label }) => (
                                <div key={value} className="z-50">
                                    <Button onClick={() => handleUploadStatusSelectionChange(value.toString())} variant={value === chosenUploadStatus ? 'solid' : 'outline'} color={value === chosenUploadStatus ? 'primary' : 'gray'}>
                                        {label}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="my-4">
                        <Divider orientation={'horizontal'} className={'b-[1px] mt-4 text-[#DBDBDB]'} />
                    </div>

                    {/* Upload File */}
                    <div className="z-50 flex flex-col">
                        <h2 className="inline-block pb-2 pl-2 text-left text-base font-semibold text-gray-700">{t('uploadFile', { ns: 'modal' })}</h2>
                        <label htmlFor="upload-file" onClick={handleUploadButton} className="z-50 cursor-pointer rounded-lg border-2 border-dashed border-gray-300 bg-blue-100 p-8 text-center">
                            <input id="upload-file" type="file" hidden onChange={(e) => handleUploadFile(e.target.files)} />
                            <div className="flex items-center justify-center pb-4">
                                <ImagesIcon />
                            </div>
                            <p className="text-black-1"> {t('browseFiles', { ns: 'modal' })}</p>
                            <p className="text-sm text-gray-400"> {t('supportedFormats', { ns: 'modal' })}</p>
                        </label>
                    </div>

                    {/* Loading Progress Bar */}
                    {isUploading && (
                        <div className="mt-2 flex w-full items-center justify-center">
                            <div className="flex h-2.5 w-full rounded-full bg-blue-200/50">
                                <div className="h-2.5 rounded-full bg-blue-500" style={{ width: `${progress}%` }}></div>
                            </div>
                            <span className="ml-2 inline-block flex-1 justify-end text-xs text-gray-600">{progress}%</span>
                        </div>
                    )}
                </div>

                <div className="z-50 flex justify-between gap-2 rounded-b-lg  bg-blue-200/30 p-4">
                    <Button variant="ghost" color="gray" className="flex-1" onClick={handleCancel}>
                        {t('cancel', { ns: 'modal' })}
                    </Button>
                    {/* @ts-ignore */}
                    <Button className="flex-1" disabled={shouldSubmitBeDisabled} onClick={handleSubmit}>
                        {t('submit', { ns: 'modal' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}
