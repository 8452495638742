import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import NewProjectDetails from '@app/components/project/new/create-project-project-details';
import { AddMembersToProject } from '@app/components/project/new/add-members-to-project';

type ProjectState = {
    step: number;
    projectId?: string;
};

type StepsObj = {
    step: number;
    title: string;
    desc: string;
    hasNext: boolean;
};

export default function AddProjectModal() {
    const { t } = useTranslation(['common', 'project']);
    const [projectState, setProjectState] = useState<ProjectState>({ step: 1 });

    const STEPS = [
        {
            step: 1,
            title: t('createProject.organizationDetailsTitle', { ns: 'project' }),
            desc: t('createProject.organizationDetailsDesc', { ns: 'project' }),
            hasNext: true
        },
        {
            step: 2,
            title: t('createProject.inviteTeamMemberTitle', { ns: 'project' }),
            desc: t('createProject.inviteTeamMemberDesc', { ns: 'project' }),
            hasNext: false
        }
    ];

    const handleStepSuccess = (projectState: ProjectState) => {
        setProjectState(projectState);
    };

    const getStyleForSteps = (stepObj: StepsObj) => {
        // the current step should be blue
        // steps before should be green
        // steps after should be grayish
        if (stepObj.step < projectState.step) {
            return 'bg-green-1 text-white';
        } else if (stepObj.step === projectState.step) {
            return 'text-white bg-[#0764EB]';
        } else {
            return 'text-[#767F91] border-[#DDE1EA] border-[1px] bg-white';
        }
    };

    return (
        <div className={'flex h-full w-full flex-col rounded-lg bg-white'}>
            <div className={'m-1 rounded-lg bg-gray-100 p-1'}>
                <div className="p-3">
                    <h1 className={'text-xl font-bold'}>{t('createNewProject')}</h1>
                    <p className={'max-w-screen-2xs break-words pt-2 !font-normal text-gray-500'}>{t('createProject.desc', { ns: 'project' })}</p>
                </div>
                <div className={'flex gap-2 rounded-lg border-[1px] border-[#FBFCFE] bg-white p-6'}>
                    <div className={'flex w-1/2 flex-col gap-4'}>
                        {STEPS.map((stepObj) => {
                            return (
                                <div key={stepObj.step} className={'flex items-start gap-6'}>
                                    <div className={`relative`}>
                                        <div className={`flex !h-7 !w-7 items-center justify-center rounded-full ${getStyleForSteps(stepObj)}`}>{stepObj.step < projectState.step ? '\u2714' : stepObj.step}</div>
                                        {stepObj.hasNext && <div className="absolute left-1/2 top-[28px] h-[calc(100%+30px)] w-[2px] -translate-x-1/2 border-r-2 border-dashed border-[#B7BCC6]"></div>}
                                    </div>

                                    <div className={'flex flex-1 flex-col gap-1'}>
                                        <h1 className={'!mb-0 !p-0 font-semibold'}>{stepObj.title}</h1>
                                        <p className={'max-w-screen-2xs break-words text-sm text-[#767F91]'}>{stepObj.desc}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={'flex-1'}>{projectState.step === 1 ? <NewProjectDetails handleStepSuccess={handleStepSuccess} /> : <AddMembersToProject projectId={projectState.projectId as string} />}</div>
                </div>
            </div>
        </div>
    );
}
