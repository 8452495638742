import Button from '@app/components/ui/button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getFileSize } from '@app/utils/pdfUtils';
import DocumentLogoSelector from '@app/components/ui/document-logo-selector';
import { useRouter } from 'next/router';
import { useModal } from '@app/components/modal-views/context';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import UploadIcon from '@app/components/icons/upload-icon';
import { Close } from '@app/components/icons/close';
import { toMidDottedStr } from '@app/utils/stringUtils';
import TusFileUploader from '@app/configs/tus-file-uploader';
import environments from '@app/configs/environments';
import ActionSupportCard, { UploadRequest } from '@app/components/ui/dialog/action-support-card';
import { Status, IUploadStatus } from '@app/components/ui/dialog/action-support-card';

const uploadStatusOptions: IUploadStatus[] = [
    { value: Status.CONVERSION, label: 'Conversion' },
    { value: Status.OCR, label: 'OCR' },
    { value: Status.ANNOTATION, label: 'Annotation' },
    { value: Status.ANONYMIZATION, label: 'Anonymization' }
];

export default function CustomUploadDocumentModal({ documentName, documentId }: { documentName: string; documentId: string }) {
    const inputFileRef = useRef(null);
    const router = useRouter();

    const { t } = useTranslation(['document', 'common']);

    const { closeModal } = useModal();

    const [uploadRequest, setUploadRequest] = useState<UploadRequest>({
        file: null,
        status: Status.ANNOTATION
    });

    const endpoint = `${environments.CLIENT_SIDE_ENDPOINT_HOST}/api/organizations/${router.query.organizationId}/projects/${router.query.projectId}/documents/${documentId}/resume/tus`;
    const fileUploader = new TusFileUploader(endpoint, environments, {
        status: uploadRequest.status.toString(),
        filename: !!uploadRequest.file ? uploadRequest.file.name : ''
    });

    const [progress, setProgress] = useState<number>(0);

    const [isUploading, setIsUploading] = useState(false);

    const handleUploadSuccess = () => {
        setIsUploading(false);
        closeModal();
        toast(`${t('toast.pdfAddedSuccessfully', { ns: 'common' })}`, { type: 'success' });
    };

    const handleUploadError = (error: { name: string; message: string; details?: string }) => {
        console.error('error when uploading: ', error);
        toast(`${t('toast.somethingWentWrong', { ns: 'common' })}`, { type: 'error', toastId: 'MANUAL_UPLOAD_ERROR' });
        setIsUploading(false);
    };

    const handleUploadProgress = (progress: any) => {
        const percentage = Math.floor((progress.uploaded / progress.total) * 100);
        setProgress(percentage);
    };

    const handleSubmit = async () => {
        setIsUploading(true);
        await fileUploader.uploadFiles(uploadRequest.file);
    };

    const handleUploadButton = () => {
        // @ts-ignore
        inputFileRef.current?.click();
    };

    const handleUploadFile = (files: FileList | null) => {
        if (!!files && files?.length > 0) {
            setUploadRequest({ ...uploadRequest, file: files[0] });
        }
    };

    const handleCancel = () => {
        fileUploader.cancelAllUploads();
        setIsUploading(false);
        closeModal();
    };

    const handleDialogClose = () => {
        fileUploader.cancelAllUploads();
        closeModal();
    };

    const handleSelectionChange = (value: string) => {
        // @ts-ignore
        setUploadRequest({ ...uploadRequest, status: value });
    };

    const shouldSubmitBeDisabled = isUploading ? true : uploadRequest.status !== Status.ANONYMIZATION && uploadRequest.file == null;

    useEffect(() => {
        if (!!fileUploader) {
            fileUploader.configureUppy(handleUploadSuccess, handleUploadError, handleUploadProgress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUploader]);

    return (
        <ActionSupportCard
            progress={progress}
            isUploading={isUploading}
            uploadStatusOptions={uploadStatusOptions}
            handleUploadStatusSelectionChange={handleSelectionChange}
            chosenUploadStatus={uploadRequest.status}
            handleSubmit={handleSubmit}
            handleUploadButton={handleUploadButton}
            handleUploadFile={handleUploadFile}
            handleCancel={handleCancel}
            handleDialogClose={handleDialogClose}
            shouldSubmitBeDisabled={shouldSubmitBeDisabled}
        />
    );
}
