import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@app/components/ui/button';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useModal } from '@app/components/modal-views/context';
import { useAddNewWordListTemplateToOrganizationMutation } from '@app/store/organization/api';
import { organizationWordTemplateRequestBody } from '@app/store/organization/types';

export default function NewOrganizationWordListTemplate({ handleStepSuccess }: any) {
    const router = useRouter();
    const initialProjectObj = { wordListName: 'Default', words: [''], organizationId: router.query.organizationId as string };

    const [newWordListTemplateObj, setNewWordListTemplateObj] = useState<organizationWordTemplateRequestBody>(initialProjectObj);

    const wordListTemplateRef = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation(['common', 'organization']);
    const { closeModal } = useModal();

    useEffect(() => {
        if (wordListTemplateRef.current) {
            wordListTemplateRef.current.focus();
        }
    }, []);

    const [createWordListTemplate, { isLoading, isSuccess, isError }] = useAddNewWordListTemplateToOrganizationMutation();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewWordListTemplateObj({ ...newWordListTemplateObj, wordListName: event.target.value });
    };

    async function handleApiCall(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        console.log('Request Body:', newWordListTemplateObj);
        const requestBody: organizationWordTemplateRequestBody = {
            ...newWordListTemplateObj
        };
        const response = await createWordListTemplate(requestBody);
        if ('error' in response) {
            // @ts-ignore
            if (response.error.originalStatus === 409) toast.error(`${t('toast.wordlistNameAlreadyExists')}`, { toastId: ToastId.WORDLIST_CONFLICT_TOAST });
            else toast.error(`${t('toast.errorCreatingNewWordList')}`, { toastId: ToastId.WORDLIST_ERROR_TOAST });
        }
        if ('data' in response) {
            toast.success(`${t('toast.wordListTemplateCreated')}`, { toastId: ToastId.WORDLIST_CREATION_TOAST });
            handleStepSuccess({ step: 2, wordListTemplateId: response.data.wordListTemplateId });
        }
    }

    const handleCancel = (e: any) => {
        e.preventDefault();
        closeModal();
    };

    return (
        <div className="flex w-[80vw] max-w-lg flex-col gap-3 p-3">
            <form onSubmit={handleApiCall}>
                <h1 className={'pb-2 text-xl font-bold'}>{t('createNewWordList', { ns: 'organization' })}</h1>

                <PdfEditorTextField
                    data-testid="enter-word-list-template-name"
                    id={'wordListName'}
                    inputRef={wordListTemplateRef}
                    placeholder={t('enterListName', { ns: 'organization' })}
                    value={newWordListTemplateObj.wordListName}
                    onChange={handleInputChange}
                    className={'!w-full !rounded-md '}
                />

                <div className={'ml-auto flex w-1/2 items-center justify-end gap-2 pt-6'}>
                    <Button data-testid="next-button" onClick={handleCancel} className={' flex-1 !border-gray-300 !bg-white !text-black-1 hover:!bg-gray-50'} variant={'outline'} isLoading={isLoading} disabled={isLoading} type="button">
                        {t('cancel')}
                    </Button>
                    <Button data-testid="next-button" className={' flex-1'} variant={'solid'} isLoading={isLoading} disabled={!newWordListTemplateObj.wordListName} type="submit">
                        {t('create', { ns: 'organization' })}
                    </Button>
                </div>
            </form>
        </div>
    );
}
