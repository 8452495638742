import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { configurationRequestBody } from '@app/store/config/types';
import { customBaseQueryWithReauth } from '../refresh/customBaseQuery';
import { AnnotationFileInterface } from '@app/components/project/configurations/configuration-index';

export const CONFIG_PATH = 'config';
const WHITELIST_TAG = 'WHITELIST_TAG';
const BLACKLIST_TAG = 'BLACKLIST_TAG';
const PARAMETER_TAG = 'PARAMETER_TAG';

export const configApi = createApi({
    reducerPath: CONFIG_PATH,
    tagTypes: [WHITELIST_TAG, BLACKLIST_TAG, PARAMETER_TAG],
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        putBlacklist: builder.mutation<void, configurationRequestBody>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/blacklist`,
                method: 'PUT',
                body: JSON.stringify(requestBody.body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: [BLACKLIST_TAG]
        }),
        getWhiteList: builder.query<Array<string>, { organizationId: string; projectId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/whitelist`,
                method: 'GET',
                cache: 'no-cache'
            }),
            providesTags: [WHITELIST_TAG]
        }),
        putWhitelist: builder.mutation<void, configurationRequestBody>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/whitelist`,
                method: 'PUT',
                body: JSON.stringify(requestBody.body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: [WHITELIST_TAG]
        }),
        getBlackList: builder.query<Array<string>, { organizationId: string; projectId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/blacklist`,
                method: 'GET',
                cache: 'no-cache'
            }),
            providesTags: [BLACKLIST_TAG]
        }),
        putParameters: builder.mutation<void, { body: AnnotationFileInterface; organizationId: string; projectId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/parameters`,
                method: 'PUT',
                body: JSON.stringify(requestBody.body)
            }),
            invalidatesTags: [PARAMETER_TAG]
        }),
        getParameters: builder.query<AnnotationFileInterface, { organizationId: string; projectId: string }>({
            query: (requestBody) => `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/config/parameters`,
            providesTags: [PARAMETER_TAG]
        })
    })
});

export const { usePutBlacklistMutation, usePutWhitelistMutation, useGetWhiteListQuery, useGetBlackListQuery, useGetParametersQuery, usePutParametersMutation } = configApi;
