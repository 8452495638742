import React, { useState } from 'react';
import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import { capitalize, MenuItem } from '@mui/material';
import { Check } from '@app/components/icons/check';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { RoleType } from '@app/models/enums/role';
import { useModal } from '@app/components/modal-views/context';
import { ProjectIdWithRoleDto } from '@app/store/project/types';
import { useDispatch } from 'react-redux';
import { updateAssignProjectSelectedRowRole } from '@app/store/project/assign-projects-slice';
import { ProjectListResponse } from '../my-organizations-sidebar/project-list-view';

export interface DropdownDto {
    label: string;
    value: RoleType;
}

interface IMemberRoles {
    currentRole: string;
    getBackgroundColor: (role: string) => string;
    id: string;
    userId: string;
    isAssignProject?: boolean;
    assignProjects?: Array<ProjectIdWithRoleDto>;
    projects?: Array<ProjectIdWithRoleDto>;
}

function ProjectRoleDropdown({ currentRole, getBackgroundColor, id, userId, projects, isAssignProject, assignProjects }: IMemberRoles) {
    const { openModal } = useModal();
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();

    const handleRoleChange = (newRole: RoleType) => {
        if (isAssignProject) {
            const updatedProjects = assignProjects?.map((project) => ({
                projectId: project.projectId,
                role: newRole
            }));
            dispatch(updateAssignProjectSelectedRowRole(updatedProjects ?? []));
        } else {
            const updatedProjects = projects?.map((project) => ({
                projectId: project.projectId,
                userRole: newRole
            }));
            openModal('CHANGE_MEMBER_ROLE', {
                projects: updatedProjects,
                userId
            });
        }
    };

    const projectMemberRole: Array<DropdownDto> = [
        { value: RoleType.ADMIN, label: t('roleType.admin') },
        { value: RoleType.EDITOR, label: t('roleType.editor') },
        { value: RoleType.REVIEWER, label: t('roleType.reviewer') },
        { value: RoleType.VIEWER, label: t('roleType.viewer') }
    ];

    return (
        <MenuDropdown
            className="!p-0 hover:!bg-transparent"
            showExpandMore
            width={216}
            onClick={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: 250,
                    overflow: 'hidden',
                    borderRadius: 2,
                    filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                    mt: 0.5,
                    px: '2px',
                    border: '1px solid #EEF0F6',
                    position: 'relative',
                    '::before': {
                        content: '""',
                        position: 'absolute',
                        top: 2,
                        left: 2,
                        right: 2,
                        bottom: 2,
                        border: '1px solid #EEF0F6',
                        borderRadius: 'inherit',
                        pointerEvents: 'none'
                    }
                }
            }}
            id={id ?? 'action-menu'}
            menuTitle={''}
            menuContent={
                <p data-testid="current-role" id={currentRole.toLowerCase()} className={cn('p2 rounded-full border-[1px] px-2 text-sm font-medium', getBackgroundColor(currentRole.toLowerCase()))}>
                    {capitalize(isAssignProject ? t(`roleType.${currentRole.toLowerCase()}`) : t(`roleType.${currentRole.toLowerCase()}`))}
                </p>
            }
        >
            {projectMemberRole.map((dropdownItem) => (
                <MenuItem data-testid={`role-${dropdownItem.value}`} onClick={() => handleRoleChange(dropdownItem.value)} className={cn('justify-between px-2 hover:bg-gray-100')} key={dropdownItem.label}>
                    <p className="p2 px-2 py-1 font-medium text-gray-900">{dropdownItem.label}</p>
                    {currentRole?.toLowerCase() === dropdownItem.value && <Check className="h-6 w-6" color="#0764EB" />}
                </MenuItem>
            ))}
        </MenuDropdown>
    );
}

export default ProjectRoleDropdown;
