export const Roles = {
    OWNER: 'owner',
    ORGANIZATION_MEMBER: 'organization member',
    ORGANIZATION_ADMIN: 'organization admin',
    MEMBER: 'member',
    PROJECT_ADMIN: 'project_admin',
    PROJECT_MEMBER: 'project member',
    EDITOR: 'editor',
    ADMIN: 'admin',
    VIEWER: 'viewer',
    REVIEWER: 'reviewer'
};

export const ProjectListHighlights = [
    {
        background: '#B9D6FF',
        highlight: '#DBEBFF',
        focus: '#0762E3'
    },
    {
        background: '#FFB4D3',
        highlight: '#FCD7E7',
        focus: '#CE065A'
    },
    {
        background: '#D8BCFF',
        highlight: '#E7D9FC',
        focus: '#7824ED'
    },
    {
        background: '#9EE7FF',
        highlight: '#CCEFFB',
        focus: '#06A1D4'
    },
    {
        background: '#FFD8A0',
        highlight: '#FBEBD4',
        focus: '#AA6505'
    }
];
