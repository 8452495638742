import React, { CSSProperties } from 'react';

import { Avatar, AvatarOwnProps, SxProps, Theme } from '@mui/material';
import cn from 'classnames';

interface IProfileImageProps extends AvatarOwnProps {
    size?: number;
    image?: string | null;
    name: string | string[] | undefined;
    typography?: string;
    className?: string;
    style?: CSSProperties;
}

const ProfileImage = ({ size = 40, image, name = ' ', className = '', typography = 'h4', style = {}, variant = 'circular' }: IProfileImageProps) => {
    return (
        <Avatar sx={{ width: size, height: size, backgroundColor: image ? 'transparent' : '#B8D5FF', ...style }} variant={variant} src={image || ''} className={`shadow-profile overflow-hidden ${className}`}>
            {!image && <span className={cn('flex items-center justify-center font-semibold', typography)}>{name[0]?.toUpperCase()}</span>}
        </Avatar>
    );
};

export default ProfileImage;
