import React from 'react';

function ActionDeleteIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.25 4.5H14.75M13.25 4.5V15C13.25 15.75 12.5 16.5 11.75 16.5H4.25C3.5 16.5 2.75 15.75 2.75 15V4.5M5 4.5V3C5 2.25 5.75 1.5 6.5 1.5H9.5C10.25 1.5 11 2.25 11 3V4.5M6.5 8.25V12.75M9.5 8.25V12.75"
                stroke="#D11706"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ActionDeleteIcon;
