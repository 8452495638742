import React from 'react';

function WarningIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_iii_6145_15760)">
                <path
                    d="M63.4089 53.02L40.0755 12.1867C39.5668 11.289 38.829 10.5422 37.9374 10.0227C37.0458 9.50321 36.0324 9.22949 35.0005 9.22949C33.9687 9.22949 32.9552 9.50321 32.0637 10.0227C31.1721 10.5422 30.4343 11.289 29.9255 12.1867L6.5922 53.02C6.07794 53.9107 5.80828 54.9214 5.81056 55.9498C5.81284 56.9783 6.08697 57.9878 6.60517 58.8762C7.12337 59.7645 7.86722 60.5001 8.76131 61.0083C9.65541 61.5165 10.668 61.7793 11.6964 61.77H58.363C59.3865 61.769 60.3917 61.4987 61.2776 60.9863C62.1636 60.4739 62.8991 59.7374 63.4104 58.8508C63.9216 57.9642 64.1907 56.9586 64.1904 55.9352C64.1901 54.9117 63.9206 53.9064 63.4089 53.02Z"
                    fill="url(#paint0_radial_6145_15760)"
                />
            </g>
            <g filter="url(#filter1_di_6145_15760)">
                <path d="M35 28.75V40.4167" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M35 49.083H35.0292" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <filter id="filter0_iii_6145_15760" x="5.81055" y="6.22949" width="58.3789" height="59.541" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.986667 0 0 0 0 0.652896 0 0 0 0 0.652896 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6145_15760" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_6145_15760" result="effect2_innerShadow_6145_15760" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.98 0 0 0 0 0.601249 0 0 0 0 0.566818 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect2_innerShadow_6145_15760" result="effect3_innerShadow_6145_15760" />
                </filter>
                <filter id="filter1_di_6145_15760" x="29" y="26.75" width="12.0293" height="32.333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.929412 0 0 0 0 0.196078 0 0 0 0 0.129412 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6145_15760" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6145_15760" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.953333 0 0 0 0 0.736363 0 0 0 0 0.716639 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6145_15760" />
                </filter>
                <radialGradient id="paint0_radial_6145_15760" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.0005 35.4999) rotate(90) scale(26.2704 29.1899)">
                    <stop stopColor="#FF7D71" stopOpacity="0.9" />
                    <stop offset="0.793017" stopColor="#DF2D1D" />
                    <stop offset="1" stopColor="#E03F30" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default WarningIcon;
