import React, { useState } from 'react';
import Button from '@app/components/ui/button';
import { useModal } from '@app/components/modal-views/context';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import { TextField } from '@mui/material';
import WarningIcon from '@app/components/icons/warning';

interface IDeleteProject {
    projectName: string;
    handleDeleteProject: () => void;
}

function DeleteProjectModal({ projectName, handleDeleteProject }: IDeleteProject) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['common', 'modal']);
    const [isLoading, setIsLoading] = useState(false);
    const [inputProjectName, setInputProjectName] = useState('');
    const handleOnChange = (e: any) => {
        setInputProjectName(e.target.value);
    };
    const handleProjectDeletionButtonClick = () => {
        setIsLoading(true);
        if (inputProjectName.toLowerCase() === projectName.toLowerCase()) {
            handleDeleteProject();
            return;
        }
        toast.error(`${t('toast.failedToDeleteProject')}`, { toastId: ToastId.PROJECT_ERROR_TOAST });
        closeModal();
    };
    return (
        <div data-testid="delete-project-modal" className="flex w-full max-w-[466px] flex-col rounded-[8px] bg-white">
            <div className="flex flex-col gap-6 p-6">
                <WarningIcon />
                <div className="flex flex-col gap-2">
                    <p className="h4 font-semibold">
                        {t('areYouSureYouWantToDelete', { ns: 'modal' })} <span className="font-semibold">{projectName}</span>?
                    </p>
                    <p className="p2 font-normal text-gray-500">{t('projectDeletionDescription', { ns: 'modal' })}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-base font-semibold text-gray-900">{t('label.enterProjectName')}</label>

                    <TextField
                        InputProps={{
                            sx: {
                                height: 48
                            }
                        }}
                        data-testid="project-name-input"
                        onChange={handleOnChange}
                        placeholder={t('placeholder.projectName')}
                        className="!h-12 !bg-gray-50"
                    />
                </div>
            </div>
            <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 p-6 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[44px] w-full !border-gray-300 !bg-white !text-gray-900  hover:!bg-gray-50'} variant="outline" onClick={closeModal}>
                    {t('cancel')}
                </Button>
                <Button
                    data-testid="delete-now"
                    isLoading={isLoading}
                    // disabled={inputProjectName.toLowerCase() !== projectName.toLowerCase()}
                    className={'h-[44px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600'}
                    variant={'solid'}
                    onClick={handleProjectDeletionButtonClick}
                >
                    {t('deleteNow')}
                </Button>
            </div>
        </div>
    );
}

export default DeleteProjectModal;
