import { atom, useAtom } from 'jotai';

export type MODAL_VIEW =
    | 'CREATE_PROJECT'
    | 'DELETE_PDF'
    | 'RESTORE_PDF'
    | 'LOADING_MODAL'
    | 'DELETE_PROJECT'
    | 'REMOVE_MEMBER'
    | 'ROLE'
    | 'REMOVE_INVITATION'
    | 'MANUAL_UPLOAD'
    | 'FILTER_BY_MODAL'
    | 'ADD_MEMBER'
    | 'ASSIGN_PROJECT'
    | 'REMOVE_PROJECT_MEMBER'
    | 'CHANGE_MEMBER_ROLE'
    | 'ADD_WORD_LIST_TEMPLATE'
    | 'DELETE_WORD_LIST_TEMPLATE'
    | 'EDIT_WORD_LIST_TEMPLATE'
    | 'CLEAR_WORD_LISTS';

const modalAtom = atom({ isOpen: false, view: '', modalProps: null });

export function useModal() {
    const [state, setState] = useAtom(modalAtom);
    const openModal = (view: MODAL_VIEW, modalProps: any = null) =>
        setState({
            ...state,
            isOpen: true,
            view,
            modalProps
        });
    const closeModal = () => {
        setState({ ...state, isOpen: false, modalProps: null, view: '' });
    };

    return {
        ...state,
        openModal,
        closeModal
    };
}
