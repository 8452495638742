import React from 'react';
import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import MenuIcon from '@app/components/icons/menu';
import { MenuItem } from '@mui/material';
import { useAppSelector } from '@app/store/hooks';
import { useTranslation } from 'next-i18next';
import { selectActiveOrganization } from '@app/store/active/slice';
import cn from 'classnames';
import { MemberDto } from '@app/store/project/types';
import { useAddSupportMemberMutation, useRemoveSupportMemberMutation } from '@app/store/organization/api';
import { isGreaterThanOrSameRole } from '@app/utils/roleUtils';
import { RoleType } from '@app/models/enums/role';
import { useModal } from '@app/components/modal-views/context';
import { useRouter } from 'next/router';
interface IActionDropDown {
    memberInfo: MemberDto;
    className?: string;
    disabled?: boolean;
}

interface DropdownProps {
    id: string;
    text: string;
    handleButton: () => void;
    shouldRender: () => boolean;
    class: string;
}

function MemberActionDropdown({ className, disabled = false, memberInfo }: IActionDropDown) {
    const { t } = useTranslation(['common', 'document', 'organization']);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [addSupportMember] = useAddSupportMemberMutation();
    const [removeSupportMember] = useRemoveSupportMemberMutation();
    const router = useRouter();

    const handleAddSupportMember = () => {
        if (!!activeOrganization && memberInfo.userInfo.userId) {
            addSupportMember({ organizationId: activeOrganization.id, userId: memberInfo.userInfo.userId });
        }
    };

    // const handleViewMemberDetais = (userId: string) => {
    //     router.push(`/${activeOrganization?.id}/members/${userId}/viewMemberDetails`);
    // };

    const handleRemoveSupportMember = () => {
        if (!!activeOrganization && memberInfo.userInfo.userId) {
            removeSupportMember({ organizationId: activeOrganization.id, userId: memberInfo.userInfo.userId });
        }
    };

    const doesLoggedUserHasEqualOrAboveRole = (): boolean => {
        return isGreaterThanOrSameRole(activeOrganization?.userRole as RoleType, memberInfo.role as RoleType);
    };
    const getSupportMemberText = (memberInfo: MemberDto) => {
        const shouldRender = doesLoggedUserHasEqualOrAboveRole();
        if (memberInfo.isSupportMember) {
            return {
                id: 'remove-support-member',
                text: t('removeAsSupportMember', { ns: 'organization' }),
                handleButton: handleRemoveSupportMember,
                shouldRender: () => shouldRender,
                class: 'text-red-500'
            };
        }

        return {
            id: 'add-support-member',
            text: t('addAsSupportMember', { ns: 'organization' }),
            handleButton: handleAddSupportMember,
            shouldRender: () => shouldRender,
            class: 'text-gray-900'
        };
    };

    const DropdownItemsArray: Array<DropdownProps> = [
        getSupportMemberText(memberInfo)
        //     {
        //     id: 'view-member-details',
        //     text: t('viewMemberDetails', { ns: 'organization' }),
        //     handleButton: () => handleViewMemberDetais(memberInfo.userInfo.userId),
        //     shouldRender: () =>
        //         doesLoggedUserHasEqualOrAboveRole() &&
        //         !['owner', 'admin'].includes(memberInfo.role?.toLowerCase() as string),
        //     class: 'text-gray-900'
        // }
    ];

    return (
        <>
            <MenuDropdown
                showExpandMore={false}
                width={220}
                disabled={disabled}
                className={className}
                onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 220,
                        overflow: 'hidden',
                        borderRadius: 2,
                        filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                        mt: 0.5,
                        padding: 0
                    }
                }}
                id={`action-menu-${memberInfo.userInfo.email}`}
                menuTitle={t('action', { ns: 'organization' })}
                menuContent={<MenuIcon className={cn(disabled && 'cursor-not-allowed opacity-50')} />}
            >
                <p className="p3 px-4 py-2 text-gray-500">{t('action', { ns: 'organization' })}</p>

                {DropdownItemsArray.filter((item) => item != null).map((dropdownItem: DropdownProps) => {
                    if (dropdownItem.shouldRender()) {
                        return (
                            <MenuItem data-testid={dropdownItem.id} key={dropdownItem.text} className="p2 flex gap-2 py-2 text-black-1" onClick={dropdownItem.handleButton}>
                                <p className={dropdownItem.class}>{dropdownItem.text}</p>
                            </MenuItem>
                        );
                    }
                })}
            </MenuDropdown>
        </>
    );
}

export default MemberActionDropdown;
