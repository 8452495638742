import _ from 'lodash';
import { pdfResponseDto } from '@app/store/pdf/types';
import { DocumentStatus } from './pdfUtils';

/**
 * Validation method to check if the given value is undefined or not.
 * @param {unknown} data - Data of any type.
 * @returns {boolean} - True if value is undefined, else false.
 */
export const isUndefined = (data: unknown): boolean => _.isUndefined(data);

/**
 * Validation method to check if the given value is null or not.
 * @param {unknown} data - Data of any type.
 * @returns {boolean} - True if value is null, else false.
 */
export const isNull = (data: unknown): boolean => _.isNull(data);

/**
 * Checks if a value is neither undefined nor null.
 * @param {unknown} data - Data of any type.
 * @returns {boolean} - True if value is neither undefined nor null.
 */
export const isNeitherUndefinedNorNull = (data: unknown): boolean => !isUndefined(data) && !isNull(data);

const COMMON_STYLES = {
    orange: { bullet: 'bg-orange-1', capsule: 'bg-orange-2', text: 'text-orange-3' },
    green: { bullet: 'bg-green-1', capsule: 'bg-green-2', text: 'text-green-3' },
    red: { bullet: 'bg-red-300', capsule: 'bg-red-200', text: 'text-red-300' },
    blue: { bullet: 'bg-[#4089f0]', capsule: 'bg-[#f2f7fe]', text: 'text-[#4089f0]' },
    yellow: { bullet: 'bg-yellow-1', capsule: 'bg-yellow-2', text: 'text-yellow-3' }
};

const STATUS_STYLES: Record<string, { bullet: string; capsule: string; text: string }> = {
    [DocumentStatus.Annotating]: COMMON_STYLES.orange,
    [DocumentStatus.Annotated]: COMMON_STYLES.green,
    [DocumentStatus.AnnotationFailed]: COMMON_STYLES.red,
    [DocumentStatus.Anonymizing]: COMMON_STYLES.orange,
    [DocumentStatus.AnonymizationFailed]: COMMON_STYLES.red,
    [DocumentStatus.Anonymized]: COMMON_STYLES.blue,
    [DocumentStatus.Saving]: COMMON_STYLES.orange,
    [DocumentStatus.SavingFailed]: COMMON_STYLES.red,
    [DocumentStatus.Saved]: COMMON_STYLES.green,
    [DocumentStatus.CreatingNewDocument]: COMMON_STYLES.yellow,
    [DocumentStatus.CreatingNewDocumentFailed]: COMMON_STYLES.red,
    [DocumentStatus.CreatedNewDocument]: COMMON_STYLES.green,
    [DocumentStatus.SentForAnonymization]: COMMON_STYLES.orange,
    [DocumentStatus.SentForSaving]: COMMON_STYLES.orange,
    [DocumentStatus.CorruptDocument]: COMMON_STYLES.red,
    [DocumentStatus.ConvertingDocumentToPdf]: COMMON_STYLES.orange,
    [DocumentStatus.ConvertingDocumentToPdfSuccess]: COMMON_STYLES.green,
    [DocumentStatus.ConvertingDocumentToPdfFailure]: COMMON_STYLES.red,
    [DocumentStatus.Uploading]: COMMON_STYLES.orange,
    [DocumentStatus.UploadingFailure]: COMMON_STYLES.red,
    [DocumentStatus.UploadingSuccess]: COMMON_STYLES.green,
    [DocumentStatus.Ocr]: COMMON_STYLES.orange,
    [DocumentStatus.OcrQueued]: COMMON_STYLES.orange,
    [DocumentStatus.OcrFailure]: COMMON_STYLES.red,
    [DocumentStatus.OcrSuccess]: COMMON_STYLES.green,
    [DocumentStatus.Validating]: COMMON_STYLES.orange,
    [DocumentStatus.ValidationSuccess]: COMMON_STYLES.green,
    [DocumentStatus.ValidationFailure]: COMMON_STYLES.red,
    [DocumentStatus.Unsupported]: COMMON_STYLES.red,
    [DocumentStatus.Merging]: COMMON_STYLES.orange,
    [DocumentStatus.MergingFailure]: COMMON_STYLES.red
};

/**
 * Determines the status properties for a given PDF status.
 * @param {pdfResponseDto} pdfInfo - The PDF response object.
 * @returns {object} - Object containing currentStatus, bulletClassName, capsuleClassName, and textClassName.
 */
export const statusProps = (pdfInfo: pdfResponseDto) => {
    let currentStatus = pdfInfo.status || 'document.status.new_document';

    if (currentStatus.includes('failed') || currentStatus.includes('failure')) {
        return { currentStatus, bulletClassName: 'bg-[#ef4637]', capsuleClassName: 'bg-[#fef6f6]', textClassName: 'text-[#ef4637]' };
    }
    return {
        currentStatus,
        bulletClassName: STATUS_STYLES[currentStatus]?.bullet || 'bg-black-1',
        capsuleClassName: STATUS_STYLES[currentStatus]?.capsule || 'bg-black-4',
        textClassName: STATUS_STYLES[currentStatus]?.text || 'text-black-3'
    };
};
