import { RemoveInvitationType } from '@app/store/invitation/types';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useModal } from '@app/components/modal-views/context';
import { useRemoveProjectInvitationMutation } from '@app/store/project/api';
import { useRemoveOrganizationInvitationMutation } from '@app/store/organization/api';
import { ToastId } from '@app/constants/ToastId';
import { useTranslation } from 'next-i18next';
import { statusErrorMessageHandler } from '@app/utils/errorUtils';

function UseRemoveInvitation() {
    const router = useRouter();
    const { closeModal } = useModal();
    const { t } = useTranslation(['common']);
    const [removeProjectInvitation] = useRemoveProjectInvitationMutation();
    const [removeOrganizationInvitation] = useRemoveOrganizationInvitationMutation();
    const handleRemoveInvitationApiRequest = async (token: string) => {
        const request: RemoveInvitationType = {
            organizationId: router.query.organizationId ?? '',
            projectId: router.query.projectId,
            invitationToken: token
        };
        let response;
        if (router.query.projectId) {
            response = await removeProjectInvitation(request);
        } else {
            response = await removeOrganizationInvitation(request);
        }
        if ('data' in response) {
            toast.success(`${t('toast.invitationRemoved')}`, { toastId: ToastId.INVITATION_SUCCESS_TOAST });
            closeModal();
        }
        if ('error' in response) {
            // @ts-ignore
            statusErrorMessageHandler(response.error.originalStatus || response.error.status, t);
            closeModal();
        }
    };

    return { handleRemoveInvitationApiRequest };
}

export default UseRemoveInvitation;
