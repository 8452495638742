import React, { useState } from 'react';
import { Close } from '@app/components/icons/close';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import { MemberDto, MemberInvitationDto } from '@app/store/project/types';
import { capitalize } from '@app/utils/stringUtils';
import { RoleType } from '@app/models/enums/role';
import { useTranslation } from 'next-i18next';
import { router } from 'next/client';
import { useRouter } from 'next/router';

function RoleModal({ member, handleActionButton, newRole }: { member: MemberDto | MemberInvitationDto; newRole: RoleType; handleActionButton: () => void }) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['modal', 'common']);
    const router = useRouter();
    const title =
        'userInfo' in member
            ? t('changeMemberRoleTitle', { email: member.userInfo.email })
            : t('changeInvitationRoleTitle', {
                  email: member.email,
                  newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, { ns: 'common' }))
              });
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div data-testid="role-modal" className="flex w-full max-w-[466px] flex-col gap-6 rounded-[8px] bg-white">
            <div className="flex flex-col items-center gap-6 px-6 py-4">
                <p className="h4">{`${t('changeRole', { ns: 'common' })}?`}</p>
                <div className="flex flex-col gap-1 text-center">
                    <p className="text-lg  font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: title }}></p>
                    <p
                        className="p2 text-gray-500"
                        dangerouslySetInnerHTML={{
                            __html: t('changeMemberRoleDescription', {
                                email: 'userInfo' in member ? member.userInfo.email : member.email,
                                type: router.query.projectId ? t('project', { ns: 'common' }) : t('organization', { ns: 'common' }),
                                newRole: capitalize(t(`roleType.${newRole.toLowerCase()}`, { ns: 'common' }))
                            })
                        }}
                    ></p>
                </div>
            </div>
            <div className="grid w-full !max-w-[466px] grid-rows-2 gap-4 px-6 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[44px]  w-full border-gray-300 !bg-white !text-gray-900 hover:!bg-gray-50'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button
                    className={'h-[44px] w-full !bg-brand hover:!bg-blue-600'}
                    variant={'solid'}
                    data-testid="change-role-button"
                    isLoading={isLoading}
                    onClick={() => {
                        setIsLoading(true);
                        handleActionButton();
                    }}
                >
                    {'userInfo' in member ? t('changeRole', { ns: 'common' }) : t('inviteNow', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
}

export default RoleModal;
