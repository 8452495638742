import React from 'react';

function PlusIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_iii_6138_59473)">
                <path
                    d="M7.70038 17.2402C7.40847 15.9252 7.45329 14.5579 7.8307 13.2649C8.20811 11.9719 8.90588 10.7951 9.85932 9.84364C10.8128 8.89219 11.991 8.19688 13.2848 7.82218C14.5786 7.44748 15.946 7.40552 17.2604 7.70019C17.9838 6.5688 18.9804 5.63771 20.1583 4.99277C21.3362 4.34782 22.6575 4.00977 24.0004 4.00977C25.3433 4.00977 26.6646 4.34782 27.8425 4.99277C29.0204 5.63771 30.017 6.5688 30.7404 7.70019C32.0567 7.40424 33.4265 7.44601 34.7224 7.82162C36.0182 8.19723 37.198 8.89448 38.1521 9.8485C39.1061 10.8025 39.8033 11.9823 40.179 13.2782C40.5546 14.5741 40.5963 15.9439 40.3004 17.2602C41.4318 17.9836 42.3629 18.9802 43.0078 20.1581C43.6528 21.336 43.9908 22.6573 43.9908 24.0002C43.9908 25.3431 43.6528 26.6644 43.0078 27.8423C42.3629 29.0202 41.4318 30.0168 40.3004 30.7402C40.595 32.0545 40.5531 33.422 40.1784 34.7158C39.8037 36.0096 39.1084 37.1878 38.1569 38.1413C37.2055 39.0947 36.0287 39.7925 34.7357 40.1699C33.4427 40.5473 32.0753 40.5921 30.7604 40.3002C30.0379 41.4359 29.0405 42.371 27.8606 43.0188C26.6807 43.6667 25.3564 44.0063 24.0104 44.0063C22.6643 44.0063 21.34 43.6667 20.1601 43.0188C18.9802 42.371 17.9829 41.4359 17.2604 40.3002C15.946 40.5949 14.5786 40.5529 13.2848 40.1782C11.991 39.8035 10.8128 39.1082 9.85932 38.1567C8.90588 37.2053 8.20811 36.0285 7.8307 34.7355C7.45329 33.4425 7.40847 32.0751 7.70038 30.7602C6.5603 30.0387 5.62121 29.0405 4.97048 27.8586C4.31975 26.6767 3.97852 25.3494 3.97852 24.0002C3.97852 22.651 4.31975 21.3237 4.97048 20.1418C5.62121 18.9598 6.5603 17.9617 7.70038 17.2402Z"
                    fill="url(#paint0_radial_6138_59473)"
                />
            </g>
            <g filter="url(#filter1_di_6138_59473)">
                <path d="M24 16V32" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 24H32" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <filter id="filter0_iii_6138_59473" x="3.97852" y="1.00977" width="40.0127" height="46.9961" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_6138_59473" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_6138_59473" result="effect2_innerShadow_6138_59473" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.203922 0 0 0 0 0.729412 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect2_innerShadow_6138_59473" result="effect3_innerShadow_6138_59473" />
                </filter>
                <filter id="filter1_di_6138_59473" x="10.25" y="14.25" width="27.5" height="27.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.073761 0 0 0 0 0.297929 0 0 0 0 0.623333 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6138_59473" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6138_59473" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.861667 0 0 0 0 0.918092 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6138_59473" />
                </filter>
                <radialGradient id="paint0_radial_6138_59473" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.9847 24.008) rotate(90) scale(19.9983 20.0061)">
                    <stop stopColor="#1857E0" stopOpacity="0.9" />
                    <stop offset="0.793017" stopColor="#386BDD" />
                    <stop offset="1" stopColor="#678CDF" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default PlusIcon;
