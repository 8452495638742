import React, { useState } from 'react';
import { useModal } from '@app/components/modal-views/context';
import Button from '@app/components/ui/button';
import WarningIcon from '@app/components/icons/warning';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ToastId } from '@app/constants/ToastId';
import { organizationWordTemplateResponse } from '@app/store/organization/types';
import { useDeleteWordListTemplateMutation } from '@app/store/organization/api';

export default function DeleteWordListModal({ wordListTemplate }: { wordListTemplate: organizationWordTemplateResponse }) {
    const { closeModal } = useModal();
    const [deleteWordList] = useDeleteWordListTemplateMutation();
    const router = useRouter();
    const { t } = useTranslation(['common', 'modal', 'organization']);
    const [isLoading, setLoading] = useState(false);
    const handleDelete = async () => {
        setLoading(true);
        await deleteWordList({
            organizationId: router.query.organizationId as string,
            templateId: wordListTemplate.uuid
        }).then((response) => {
            if ('data' in response) {
                closeModal();
                toast(`${t('toast.templateRemoved')}`, {
                    type: 'success',
                    toastId: ToastId.DELETE_TEMPLATE_SUCCESS_TOAST
                });
            } else {
                toast(`${t('toast.failedToDeleteWordlist')}`, { type: 'error', toastId: ToastId.DELETE_TEMPLATE_ERROR_TOAST });
            }
            setLoading(false);
        });
    };

    return (
        <div data-testid="delete-modal" className="flex w-full max-w-[500px] flex-col gap-5 rounded-xl border-[1px] border-gray-300 bg-white ">
            <div className="mx-1 flex flex-col items-center gap-5 border-b-[1px] border-gray-200 px-6 py-4">
                <WarningIcon />
                <div className={'h4 text-center font-medium text-gray-900'}>
                    {t('areYouSureYouWantToDelete', { ns: 'modal' })}
                    <Typography className="label break-all"> {`”${wordListTemplate.wordListName}” ${t('template', { ns: 'organization' })}`}?</Typography>
                </div>
            </div>
            <div className="grid w-full !max-w-[500px] grid-rows-2 gap-2 px-4 pb-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                <Button className={'h-[45px]  w-full !border-gray-300 !bg-white  !text-gray-900 hover:!bg-gray-50 hover:!text-gray-900'} variant="outline" onClick={closeModal}>
                    {t('cancel', { ns: 'common' })}
                </Button>
                <Button isLoading={isLoading} className={'h-[45px] w-full rounded-[4px] !bg-red-500 hover:!bg-red-600'} variant={'solid'} onClick={handleDelete}>
                    {t('deleteNow')}
                </Button>
            </div>
        </div>
    );
}
