export const dataTableCustomStyles = {
    table: {
        style: {
            border: 'none',
            background: 'transparent'
        }
    },
    headRow: {
        style: {
            background: '#F8F9FD',
            fontSize: '12px',
            fontWeight: 600,
            color: '#767F91',
            gap: '40px'
            // paddingLeft: '24px',
        }
    },
    headCells: {
        style: {
            border: 'none',
            color: '#6E6E6E',
            fontSize: '14px',
            lineHeight: '21px',
            paddingLeft: '0 !important',
            paddingRight: '0 !important'
        }
    },
    cells: {
        style: {
            padding: '0',
            color: '#0E192F'
        }
    },
    rows: {
        style: {
            backgroundColor: 'transparent',
            border: 'none',
            outlineWidth: '0',
            paddingLeft: '0',
            paddingRight: '0',
            paddingTop: '16px',
            paddingBottom: '16px',
            gap: '40px',
            '&:hover:not(:disabled)': {
                cursor: 'pointer',
                backgroundColor: '#E8F2FF'
            }
        }
    },
    pagination: {
        style: {
            border: 'none',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px'
        }
    }
};

export const noHoverTableStyles = {
    ...dataTableCustomStyles, // Copy existing styles
    rows: {
        style: {
            ...dataTableCustomStyles.rows.style, // Keep other styles
            '&:hover:not(:disabled)': {
                backgroundColor: 'transparent !important',
                cursor: 'default !important'
            }
        }
    }
};

export const assignedTableStyles = {
    ...dataTableCustomStyles,
    headRow: {
        style: {
            display: 'flex',
            background: '#F8F9FD',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '0px'
        }
    },
    headCells: {
        style: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: '0 !important',
            paddingRight: '0 !important'
        }
    },
    rows: {
        style: {
            ...dataTableCustomStyles.rows.style,
            gap: '0px',
            paddingTop: '12px',
            paddingBottom: '12px',
        }
    }
};
