import { Stack } from '@mui/system';
import { Autocomplete, createFilterOptions, Paper } from '@mui/material';
import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
// @ts-ignore
import { Popper } from '@mui/base';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { MemberDto, MemberInvitationDto } from '@app/store/project/types';
import ProfileImage from '@app/components/auth/profile-image';
import Button from '@app/components/ui/button';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { InvitationSendBodyType } from '@app/store/invitation/types';
import { RoleType } from '@app/models/enums/role';
import { useSendInvitationToMemberFromProjectMutation } from '@app/store/project/api';
import { useSendInvitationToMemberFromOrganizationMutation } from '@app/store/organization/api';
import { ToastId } from '@app/constants/ToastId';
import { initialAuthState } from '@app/store/auth/authSlice';
import { useModal } from '../modal-views/context';

interface AddMembersAutocompleteProps {
    dropdownListMembers: Array<MemberDto>;
    handleAddMembers: (member: MemberDto | MemberInvitationDto) => void;
}

function AddMembersAutocomplete({ dropdownListMembers, handleAddMembers }: AddMembersAutocompleteProps) {
    const { closeModal } = useModal();
    const { t } = useTranslation(['common', 'project', 'modal']);
    const router = useRouter();
    const [inviteUserToProject, inviteUserToProjectResponse] = useSendInvitationToMemberFromProjectMutation();
    const [inviteUserToOrganization, inviteUserToOrganizationResponse] = useSendInvitationToMemberFromOrganizationMutation();
    const [isAutoCompleteOpen, setAutoCompleteState] = useState(false);
    const filter = createFilterOptions<MemberDto>();

    const successToast = () => {
        toast(`${t('toast.userHasBeenInvited')}`, { type: 'success', toastId: ToastId.INVITATION_SUCCESS_TOAST });
    };
    const errorToast = () => {
        toast(`${t('toast.invitationSendFailed')}`, { type: 'error', toastId: ToastId.INVITATION_ERROR_TOAST });
    };

    function AutocompleteOptionsUI({ chosenMember }: { chosenMember: MemberDto }) {
        async function handleInviteMember() {
            const requestBody: InvitationSendBodyType = {
                organizationId: router.query.organizationId as string,
                email: chosenMember.userInfo.name,
                role: router.query.projectId ? RoleType.VIEWER.toLowerCase() : RoleType.MEMBER.toLowerCase(),
                projectId: router.query.projectId as string
            };
            let response;
            if (router.query.projectId) {
                response = await inviteUserToProject(requestBody);
            } else {
                response = await inviteUserToOrganization(requestBody);
            }
            if ('data' in response) {
                successToast();
                handleAddMembers(response.data);
                setAutoCompleteState(false);
                closeModal();
            }
            if ('error' in response) {
                //@ts-ignore
                if (response.error.status === 409) {
                    toast.error(`${t(`toast.alreadyAMember`, { name: chosenMember.userInfo.name })}`, { toastId: ToastId.MEMBER_ERROR_TOAST });
                } else {
                    errorToast();
                    setAutoCompleteState(false);
                }
            }
        }

        const getOnClickHandler = (chosenMember: MemberDto) => {
            return chosenMember.userInfo.userId === '-1'
                ? () => {}
                : () => {
                      handleAddMembers(chosenMember);
                      setAutoCompleteState(false);
                  };
        };

        return (
            <div key={chosenMember.userInfo.email} onClick={getOnClickHandler(chosenMember)} className={' flex w-full cursor-pointer items-center justify-between px-2 py-2 hover:bg-gray-200'}>
                <div className={'flex w-full items-center gap-4 '}>
                    <ProfileImage name={chosenMember.userInfo.name ?? chosenMember.userInfo.email} image={chosenMember.userInfo.picture} className="rounded-md" />
                    <div>
                        <p className="font-semibold text-gray-900">{chosenMember.userInfo.name}</p>
                        <p className={'font-medium text-gray-500'}>{chosenMember.userInfo.email}</p>
                    </div>
                </div>
                {chosenMember.userInfo.userId === '-1' && (
                    <Button data-testid="invite-button" size={'small'} isLoading={inviteUserToProjectResponse.isLoading || inviteUserToOrganizationResponse.isLoading} className={'w-1/4 rounded-md bg-brand'} onClick={handleInviteMember}>
                        {t('invite')}
                    </Button>
                )}
            </div>
        );
    }

    return (
        <Stack>
            <Autocomplete
                open={isAutoCompleteOpen}
                onOpen={() => {
                    setAutoCompleteState(true);
                }}
                onClose={() => {
                    setAutoCompleteState(false);
                }}
                data-testid="add-member-input"
                disableClearable
                noOptionsText={router.query.projectId ? t('noMembersAvailable', { ns: 'project' }) : t('toast.pleaseEnterAValidEmail')}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (emailRegex.test(params.inputValue.trim())) {
                            filtered.push({
                                role: router.query.projectId ? RoleType.VIEWER : RoleType.MEMBER,
                                userInfo: {
                                    ...initialAuthState,
                                    userId: '-1',
                                    email: t('inviteDescription', {
                                        email: params.inputValue.trim().toLowerCase(),
                                        name: router.query.projectId ? t('project') : t('organization')
                                    }),
                                    name: params.inputValue.toLowerCase().trim(),
                                    picture: null
                                }
                            });
                        }
                    }
                    return filtered;
                }}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.userInfo.email;
                }}
                renderOption={(_props, option) => {
                    // const memberObj = dropdownListMembers.find((el) => el.userInfo.email === option);
                    return <AutocompleteOptionsUI key={option.userInfo.userId} chosenMember={option} />;
                }}
                // options={dropdownListMembers.map(member => member.userInfo.email)}
                options={dropdownListMembers}
                renderInput={(params) => (
                    <PdfEditorTextField
                        {...params}
                        placeholder={t('searchMembers', { ns: 'project' })}
                        className={'!h-[40px] !w-full !rounded-lg'}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search'
                        }}
                    />
                )}
                PopperComponent={({ anchorEl, children, ...popperProps }: any) => {
                    return (
                        <Popper anchorEl={anchorEl} {...popperProps}>
                            <Paper>
                                <div className={'!mt-2 overflow-auto'}>{children}</div>
                            </Paper>
                        </Popper>
                    );
                }}
            />
        </Stack>
    );
}

export default AddMembersAutocomplete;
