import { useAppSelector } from '@app/store/hooks';
import { selectActiveOrganization } from '@app/store/active/slice';
import { useAddProjectsToMemberMutation, useGetProjectsWhereOrganizationMemberNotInvolvedQuery } from '@app/store/project/api';
import NonAssignedProject from '@app/components/datatable/project-table/non-assigned-projects';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import SearchInput from '@app/components/ui/input/search-input';
import { useRouter } from 'next/router';
import cn from 'classnames';
import Button from '@app/components/ui/button/button';
import { useModal } from '../context';
import { Close } from '@app/components/icons/close';
import { useDispatch } from 'react-redux';
import { AddProjectsToMemberRequest } from '@app/store/project/types';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/ToastId';
import { selectAssignedProjectInfo, setAssignedProjectSelectedRows, setToggleCleared } from '@app/store/project/assign-projects-slice';
import { RoleType } from '@app/models/enums/role';

function AssignProjectModal() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { t } = useTranslation(['common', 'organization']);
    const [searchQuery, setSearchQuery] = useState('');
    const router = useRouter();
    const dispatch = useDispatch();
    const { userId } = router.query;
    const assignProject = useAppSelector(selectAssignedProjectInfo);
    const { data, isLoading } = useGetProjectsWhereOrganizationMemberNotInvolvedQuery({
        organizationId: activeOrganization?.id,
        userId: userId,
        query: searchQuery
    });
    const [assignProjectsToAfMember, { isLoading: isSaving }] = useAddProjectsToMemberMutation();
    const isProjectSelected = assignProject.selectedRows.length > 0;
    const { closeModal } = useModal();

    const handleSearch = (event: any) => {
        event.preventDefault();
        const query = event.target.value.trim();
        setSearchQuery(query);
    };

    const handleSave = async () => {
        if (!activeOrganization?.id || !userId) return;

        const requestBody: AddProjectsToMemberRequest = {
            organizationId: activeOrganization.id,
            userId: userId as string,
            projects: assignProject.selectedRows.map((assignProject) => ({
                projectId: assignProject.projectId,
                role: assignProject.role ?? RoleType.VIEWER
            }))
        };
        try {
            await assignProjectsToAfMember(requestBody).then((response) => {
                if ('data' in response) {
                    dispatch(setToggleCleared(!assignProject.toggleCleared));
                    dispatch(setAssignedProjectSelectedRows([]));
                    closeModal();
                    toast.success(`${t('toast.projectAssigned')}`, { toastId: ToastId.ASSIGN_PROJECT_TOAST });
                }
                if ('error' in response) {
                }
            });
        } catch (error) {
            console.error('Failed to assign projects to member:', error);
        }
    };

    return (
        <div className="rounded-xl bg-gray-200">
            <div className="flex max-h-min items-center justify-between gap-20 p-4">
                <h4 className="p5 flex items-center justify-center gap-2 text-gray-900">{t('assignProject', { ns: 'organization' })}</h4>
                <Close onClick={closeModal} className="z-10 h-6 w-6 cursor-pointer" />
            </div>
            <div className="relative w-full">
                <div className={cn('absolute left-0 right-0 top-0 flex w-full items-center justify-between rounded-xl bg-white px-4 py-1.5', isProjectSelected ? 'z-0 ' : 'z-10')}>
                    <SearchInput id="project-search" placeholder={t('searchProject', { ns: 'organization' })} handleSearch={handleSearch} fullWidth />
                </div>
            </div>
            <NonAssignedProject data={data} isLoading={isLoading} />

            {isProjectSelected && (
                <div className="grid w-full grid-rows-2 gap-4 rounded-b-xl bg-white px-4 py-4 sm:grid-cols-2 sm:grid-rows-1 sm:gap-6">
                    <Button className={'h-[44px]  w-full border-gray-300 !bg-white !text-gray-900 hover:!bg-gray-50'} variant="outline" onClick={closeModal}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <Button className={'h-[44px] w-full !bg-brand hover:!bg-blue-600'} variant={'solid'} data-testid="change-role-button" isLoading={isSaving} onClick={handleSave}>
                        {t('save', { ns: 'organization' })}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default AssignProjectModal;
